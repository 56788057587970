<template>
  <div class="splash-screen">
    <v-progress-linear indeterminate></v-progress-linear>

    <div class="splash-screen-logo">
      <img src="@/assets/logo.svg" />
    </div>

    <v-slide-y-transition>
      <div class="splash-screen-retry" v-show="showRetryButton">
        <div>Couldn't connect, please click below to retry</div>
        <v-btn text color="error" @click="retryFetch">
          <v-icon>mdi-cached</v-icon>
          Retry
        </v-btn>
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
import { UserInfoStore } from "@/stores/UserInfoStore";
import { OrgInfoStore } from "@/stores/OrgInfoStore";

const maxRetries = 4;

export default {
  name: "SplashScreen",
  data() {
    return {
      retries: 0,
      showRetryButton: false,
    };
  },
  methods: {
    // TODO fix this... repeatedly calling after init was successful
    initRetryTimer: function () {
      setTimeout(() => {
        if (this.retries < maxRetries) {
          this.retryFetch();
        } else {
          if (!UserInfoStore.isInitialized || !OrgInfoStore.isInitialized) {
            this.showRetryButton = true;
          }
        }
        this.retries = this.retries + 1;
      }, 5000);
    },
    retryFetch: function () {
      UserInfoStore.fetch();
      OrgInfoStore.fetch();
      this.showRetryButton = false;
      this.initRetryTimer();
    },
  },
  mounted() {
    this.initRetryTimer();
  },
  onBeforeUnmount() {},
};
</script>

<style scoped>
.splash-screen {
  width: 100%;
  height: 100%;
}

.splash-screen-logo {
  width: 100%;
  height: 100%;
}

.splash-screen-logo > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.splash-screen-retry {
  position: absolute;
  top: calc(50% + 140px);
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1.8em;
  margin-top: 4px;
}
</style>
