<template>
  <div id="map-container" :style="getMapStyle">
    <v-snackbar
      timeout="-1"
      v-model="snackbarEnabled"
      :absolute="true"
      class="map-snackbar"
    >
      <v-icon v-if="snackbarIcon !== undefined" left
        >{{ snackbarIcon }}
      </v-icon>
      {{ snackbarContent }}
    </v-snackbar>
  </div>
</template>

<script>
// leaflet imports
import "leaflet/dist/leaflet.css";
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css";
import "leaflet-draw/dist/leaflet.draw.css";

import L from "leaflet";
import _ from "lodash";
import "leaflet-draw";
import "leaflet.locatecontrol";

import { ConfigurationStore } from "@/stores/ConfigurationStore.js";
import { LayoutStore } from "@/stores/LayoutStore.js";
import { MapDataStore } from "@/stores/MapDataStore.js";
import { NavigationStore } from "@/stores/NavigationStore.js";
// import ApiService from "@/services/ApiService";

const COMPONENT_NAME = "MapContainer";

export default {
  name: COMPONENT_NAME,
  props: {
    navWidth: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getMapStyle: function () {
      if (LayoutStore.isMobile()) {
        return {};
      }
      return {
        width: `calc(100% - ${this.navWidth}px)`,
        left: `${this.navWidth}px`,
        position: "absolute",
      };
    },
  },
  data() {
    return {
      map: null,
      layerGroups: {},
      polygonControl: undefined,
      snackbarContent: undefined,
      snackbarIcon: undefined,
      snackbarEnabled: false,
      baseTileLayer: undefined,
      roadsTileLayer: undefined,
    };
  },
  methods: {},
  mounted() {
    // TODO get this from users settings
    this.map = L.map("map-container").setView(
      [34.07927362329528, -118.3413201570511],
      17
    );
    MapDataStore.map = this.map;

    // TODO fix this...
    // L.TileLayer.AuthHeaders = L.TileLayer.WMS.extend({
    //   createTile(coords, done) {
    //     const url = this.getTileUrl(coords);
    //     const img = document.createElement("img");
    //     ApiService.makeApiCall(url, { method: "GET" })
    //       .then((response) => response.blob())
    //       .then((blob) => {
    //         img.src = URL.createObjectURL(blob);
    //         done(null, img);
    //       });
    //     return img;
    //   },
    // });
    // L.tileLayer.authHeaders = (url, options) =>
    //   new L.TileLayer.AuthHeaders(url, options);

    // this.baseTileLayer = L.tileLayer.authHeaders(
    this.baseTileLayer = L.tileLayer(ConfigurationStore.map.baseTileServer, {
      maxZoom: ConfigurationStore.map.maxZoom,
      minZoom: ConfigurationStore.map.minZoom,
      zoomDelta: 0.25,
      zoomSnap: 0,
    });
    this.map.addLayer(this.baseTileLayer);

    // this.roadsTileLayer = L.tileLayer.authHeaders(
    this.roadsTileLayer = L.tileLayer(ConfigurationStore.map.roadsTileServer, {
      maxZoom: ConfigurationStore.map.maxZoom,
      minZoom: ConfigurationStore.map.minZoom,
      zoomDelta: 0.5,
    });

    this.map.zoomControl.remove();

    L.control
      .locate({
        position: "bottomright",
      })
      .addTo(this.map);

    L.control
      .zoom({
        position: "bottomright",
      })
      .addTo(this.map);

    MapDataStore.methods.addLayer = (layerGroup, layer, minZoom) => {
      if (this.layerGroups[layerGroup] === undefined) {
        this.layerGroups[layerGroup] = L.featureGroup().addTo(this.map);
        this.layerGroups[layerGroup].minZoomLevel =
          minZoom === undefined ? 0 : minZoom;
      }
      this.layerGroups[layerGroup].addLayer(layer);
    };
    MapDataStore.methods.removeLayer = (layerGroup, layer) => {
      if (this.layerGroups[layerGroup] === undefined) {
        console.warn(`Unknown layer: ${layerGroup}`);
      }
      this.layerGroups[layerGroup].removeLayer(layer);
    };

    MapDataStore.methods.changeToBaseView = () => {
      this.map.removeLayer(this.roadsTileLayer);
      this.map.addLayer(this.baseTileLayer);
    };
    MapDataStore.methods.changeToRoadView = () => {
      this.map.removeLayer(this.baseTileLayer);
      this.map.addLayer(this.roadsTileLayer);
    };
    MapDataStore.methods.clearLayer = (layerGroup) => {
      if (this.layerGroups[layerGroup]) {
        console.log(`Clearing layers for ${layerGroup}`);
        this.map.removeLayer(this.layerGroups[layerGroup]);
        delete this.layerGroups[layerGroup];
      }
    };
    MapDataStore.methods.clearMap = () => {
      _.forEach(this.layerGroups, (layerGroup, layer) => {
        console.log(`Clearing layers for ${layer}`);
        this.map.removeLayer(layerGroup);
        delete this.layerGroups[layer];
      });
    };
    MapDataStore.methods.zoomToLayerGroup = (layerGroup) => {
      if (this.layerGroups[layerGroup]) {
        this.map.fitBounds(this.layerGroups[layerGroup].getBounds());
      } else {
        console.warn(
          `Could not zoom to layer group. Layer group ${layerGroup} not found`
        );
      }
    };
    MapDataStore.methods.zoomToPosition = (position) => {
      this.map.panTo(new L.LatLng(position.lat, position.lon));
    };

    // register callback
    NavigationStore.registerChangeListener(COMPONENT_NAME, () =>
      MapDataStore.methods.clearMap()
    );

    MapDataStore.methods.clearSnackbar = () => {
      this.snackbarContent = undefined;
      this.snackbarIcon = undefined;
      this.snackbarEnabled = false;
    };
    MapDataStore.methods.setSnackbar = (content, icon) => {
      this.snackbarContent = content;
      this.snackbarIcon = icon;
      this.snackbarEnabled = true;
    };

    MapDataStore.methods.refreshMap = () => {
      this.map.invalidateSize();
    };

    // Leaflet draw
    MapDataStore.methods.enablePolygonDraw = () => {
      if (this.polygonControl !== undefined) {
        return;
      }
      this.polygonControl = new L.Draw.Polygon(this.map);
      this.polygonControl.enable();
    };
    MapDataStore.methods.disablePolygonDraw = () => {
      if (this.polygonControl) {
        this.polygonControl.disable();
      }
      this.polygonControl = undefined;
    };

    // start new polygon drawing if draw is currently enabled and current draw ends
    this.map.on("draw:created", () => {
      if (this.polygonControl) {
        this.polygonControl = new L.Draw.Polygon(this.map);
        this.polygonControl.enable();
      }
    });

    // TODO change these to a loop
    this.map.on("draw:created", (e) => {
      _.forEach(MapDataStore.callbacks.polygonSelect, (callback, key) => {
        console.log("Leaflet.Draw created : calling " + key);
        callback(e, this.map);
      });
    });
    this.map.on("click", (e) => {
      _.forEach(MapDataStore.callbacks.click, (callback, key) => {
        console.log("click : calling " + key);
        callback(e, this.map);
      });
    });
    this.map.on("zoomend", (e) => {
      const zoom = e.target._zoom;
      _.forEach(this.layerGroups, (group) => {
        if (typeof group.minZoomLevel === "number") {
          if (zoom < group.minZoomLevel) {
            group.removeFrom(this.map);
          } else if (zoom >= group.minZoomLevel) {
            group.addTo(this.map);
          }
        }
      });
    });
  },
  onBeforeUnmount() {
    // TODO fix this...
    if (this.map) {
      this.map.remove();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#map-container {
  height: 100%;
  transition: all 0.4s ease;
  z-index: 100;
}

.map-snackbar {
  z-index: 1000;
}
</style>
