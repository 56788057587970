<template>
  <div>
    <v-progress-linear :active="isLoading" indeterminate></v-progress-linear>
    <v-btn
      v-for="format in supportedFormats"
      :key="format"
      @click="downloadFile(format)"
    >
      {{ "Export (" + format + ")" }}
    </v-btn>
  </div>
</template>

<script>
import _ from "lodash";

import ReportService from "@/services/ReportService";
import AuthUtil from "@/util/AuthUtil";

const COMPONENT_NAME = "SurveyExport";

export default {
  name: COMPONENT_NAME,
  computed: {},
  components: {},
  data: function () {
    return {
      isLoading: false,
      supportedFormats: [],
    };
  },
  methods: {
    downloadFile: function (format) {
      AuthUtil.getToken().then((auth) => {
        const accessToken = _.get(auth, "accessToken");
        const tokenInput = document.createElement("input");
        tokenInput.setAttribute("type", "hidden");
        tokenInput.setAttribute("name", "token");
        tokenInput.setAttribute("value", accessToken);
        const form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("target", "_blank");
        form.setAttribute("action", this.getDownloadLink(format));
        form.appendChild(tokenInput);
        document.body.appendChild(form);
        form.submit();
        form.remove();
      });
    },
    getDownloadLink: function (format) {
      return ReportService.getReportDownloadLink(
        ReportService.REPORT_TYPES.SURVEY,
        format,
        this.survey.id
      );
    },
  },
  mounted() {
    this.isLoading = true;
    ReportService.getSupportedFormatsByType(
      ReportService.REPORT_TYPES.SURVEY
    ).then((reportTypes) => {
      this.isLoading = false;
      this.supportedFormats = _.map(reportTypes, _.camelCase);
    });
  },
  props: {
    survey: Object,
  },
  watch: {},
};
</script>

<style>
</style>
