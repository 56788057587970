import { reactive } from 'vue'

export const MapDataStore = reactive({
    // event type -> component ID -> callback associated with that component
    callbacks: {
        click: {},
        polygonSelect: {},
    },
    methods: {
        addLayer: () => undefined,
        changeToBaseView: () => undefined,
        changeToRoadView: () => undefined,
        clearLayer: () => undefined,
        clearMap: () => undefined,
        clearSnackbar: () => undefined,
        disablePolygonDraw: () => undefined,
        enablePolygonDraw: () => undefined,
        refreshMap: () => undefined,
        removeLayer: () => undefined,
        setSnackbar: () => undefined,
        zoomToLayerGroup: () => undefined,
        zoomToPosition: () => undefined,
    },
    map: undefined
})