<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredResults"
      item-key="name"
      :search="search"
      class="place-detail-table"
      :mobile-breakpoint="0"
    >
      <template v-slot:top>
        <div class="data-table-header">
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
            append-icon="mdi-magnify"
          ></v-text-field>
          <v-menu
            offset-y
            v-if="responseTypes.length !== 0"
            class="data-table-filter-dropdown"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="filterButtonColor"
                icon
                dark
                v-bind="attrs"
                v-on="on"
                class="data-table-header-button"
              >
                <v-icon> mdi-filter </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in responseTypes" :key="index">
                <v-simple-checkbox
                  @input="filterSelectToggle(item)"
                  :value="isResponseTypeSelected(item)"
                  :label="item"
                ></v-simple-checkbox>
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="place in items"
            :key="place.mapObject.id"
            @mouseover="place.polygonMouseOver()"
            @mouseout="place.polygonMouseOut()"
            @click="openPlacePage(place.mapObject.id)"
          >
            <td>{{ place.mapObject.address.addressLine1 }}</td>
            <td>{{ place.responseCode.abbreviation }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from "lodash";

const COMPONENT_NAME = "SurveyPlaceDetail";

export default {
  name: COMPONENT_NAME,
  computed: {
    filterButtonColor() {
      return this.selectedResponseTypes.length === this.responseTypes.length
        ? "secondary"
        : "primary";
    },
    filteredResults() {
      if (this.selectedResponseTypes) {
        return _.filter(this.survey.places, (place) =>
          _.includes(
            this.selectedResponseTypes,
            place.responseCode.abbreviation
          )
        );
      }
      return Object.values(this.survey.places);
    },
  },
  components: {},
  data: function () {
    return {
      headers: [
        {
          text: "Address",
          value: "mapObject.address.addressLine1",
          filterable: true,
        },
        {
          text: "Response",
          value: "responseCode.abbreviation",
          filterable: true,
        },
      ],
      responseTypes: [],
      search: "",
      selectedResponseTypes: [],
    };
  },
  methods: {
    filterSelectToggle: function (responseType) {
      if (_.includes(this.selectedResponseTypes, responseType)) {
        this.selectedResponseTypes = _.remove(
          this.selectedResponseTypes,
          (type) => type !== responseType
        );
      } else {
        this.selectedResponseTypes.push(responseType);
      }
    },
    isResponseTypeSelected: function (responseType) {
      return _.includes(this.selectedResponseTypes, responseType);
    },
    setResponseTypes: function (survey) {
      this.updateResponseTypes(survey);
      this.selectedResponseTypes = _.clone(this.responseTypes);
    },
    updateResponseTypes: function (survey) {
      this.responseTypes = _.uniq(
        _.map(survey.places, (resp) => resp.responseCode.abbreviation)
      );
    },
  },
  mounted() {
    this.setResponseTypes(this.survey);
  },
  props: {
    openPlacePage: Function,
    placeMouseOver: Function,
    placeMouseOut: Function,
    survey: Object,
    surveyRev: Number,
  },
  watch: {
    survey: function (newSurvey) {
      this.setResponseTypes(newSurvey);
    },
    surveyRev: function () {
      this.updateResponseTypes(this.survey);
    },
  },
};
</script>

<style>
.place-detail-filter {
  z-index: 100;
}

.place-detail-filter > div {
  padding: 0px;
}

.place-detail-table .v-data-footer__select {
  display: none;
}

.place-detail-table .v-data-footer {
  justify-content: right;
  padding: 8px;
}

.data-table-header {
  display: flex;
}

.data-table-header .data-table-header-button {
  margin-top: 16px;
  margin-right: 20px;
}

.data-table-filter-dropdown {
  z-index: 120;
}
</style>
