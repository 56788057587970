<template>
  <v-card>
    <v-card-text>
      <v-text-field
        solo
        v-model="searchTerm"
        class="map-search-text-field"
        label="Search"
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
      <ul v-if="items.length > 0" class="search-results">
        <li
          v-for="item in items"
          :class="getSearchResultClass(item)"
          :key="getSearchResultKey(item)"
          @click="selectedAddress = item"
        >
          {{ item.addressString }}
        </li>
      </ul>
      <div v-else class="map-search-no-results">No Results</div>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        @click="goToAddress()"
        :disabled="Object.keys(selectedAddress).length === 0"
      >
        Go to Address
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";

import MapQueryService from "@/services/MapQueryService";

import { MapDataStore } from "@/stores/MapDataStore";
import LeafletUtil from "@/util/LeafletUtil";

const COMPONENT_NAME = "MapSearchTool";
const COMPONENT_MARKER_LAYER = `${COMPONENT_NAME}-Markers`;

export default {
  name: COMPONENT_NAME,
  components: {},
  computed: {},
  data: function () {
    return {
      items: [],
      searchTerm: "",
      selectedAddress: {},
    };
  },
  methods: {
    goToAddress: function () {
      MapDataStore.methods.clearLayer(COMPONENT_MARKER_LAYER);

      const marker = LeafletUtil.getMapMarker(this.selectedAddress.center);
      MapDataStore.methods.addLayer(COMPONENT_MARKER_LAYER, marker);

      MapDataStore.methods.zoomToPosition(this.selectedAddress.center);

      if (this.goToAddressClick) {
        this.goToAddressClick(this.selectedAddress);
      }
      this.selectedAddress = {};
      this.items = [];
      this.searchTerm = "";
    },
    getSearchResultClass: function (result) {
      if (_.isEmpty(this.selectedAddress)) {
        return "";
      }
      if (
        this.getSearchResultKey(result) ===
        this.getSearchResultKey(this.selectedAddress)
      ) {
        return "search-result-selected";
      }
      return "";
    },
    getSearchResultKey: function (result) {
      return `${result.addressString}-${result.center.lat}-${result.center.lon}`.replace(
        /\s+/g,
        "-"
      );
    },
    searchFunc: function (val) {
      if (val !== undefined && val !== null) {
        MapQueryService.searchAddressByQuery(val).then(
          (resp) => (this.items = resp)
        );
      }
    },
  },
  mounted() {},
  props: {
    goToAddressClick: Function,
  },
  watch: {
    searchTerm: function (query) {
      this.searchFunc(query);
    },
  },
};
</script>

<style scoped>
.map-search-text-field {
  padding-top: 24px;
}

.map-search-no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 420px;
}

ul.search-results {
  padding: 0px;
  margin-top: -16px;
  height: 420px;
  overflow: auto;
}

.search-results li {
  list-style-type: none;
  padding: 8px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
  border-radius: 4px;
}

.search-results li:last-child {
  border-bottom: none;
}

.search-results li:hover {
  background: rgba(0, 0, 0, 0.12);
}

.search-result-selected {
  background: #e3edf8;
}
</style>
