import { ConfigurationStore } from "../stores/ConfigurationStore.js";
import ApiService from "./ApiService.js";

export default class SurveyService {

    static async createSurvey(body) {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/survey`, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' } });
        return await response.text();
    }

    static async getSurveys(page, pageSize, query, sortField, sortOrder, isDone) {
        const endpoint = `${ConfigurationStore.backend}/api/survey?` + new URLSearchParams({
            p: page,
            ps: pageSize,
            q: query,
            s: sortOrder,
            sf: sortField,
            d: isDone
        });
        const response = await ApiService.makeApiCall(endpoint, { method: 'GET' });
        return await response.json();
    }

    static async getSurvey(id) {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/survey/${id}`, { method: 'GET' });
        return await response.json();
    }

    static async finishSurvey(id) {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/survey/${id}/complete`, { method: 'POST' });
        return await response.text();
    }

    static async deleteSurvey(id) {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/survey/${id}`, { method: 'DELETE' });
        return await response.text();
    }

    static async setPlaceResponse(id, placeId, surveyResponse) {
        return await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/survey/${id}/${placeId}`, { method: 'POST', body: JSON.stringify(surveyResponse), headers: { 'Content-Type': 'application/json' } });
    }

    static async addSubunit(id, placeId, subunitRequest) {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/survey/${id}/address/${placeId}`, { method: 'POST', body: JSON.stringify(subunitRequest), headers: { 'Content-Type': 'application/json' } });
        return await response.json();
    }

    static async addPlaceOverride(id, placeId, override) {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/survey/${id}/override/${placeId}`, { method: 'POST', body: JSON.stringify(override), headers: { 'Content-Type': 'application/json' } });
        return await response.json();
    }
}