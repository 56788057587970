<template>
  <div class="page-container-header">
    <div class="nav-header-icon">
      <div v-if="iconClick === undefined">
        <v-icon>{{ icon }}</v-icon>
      </div>
      <div v-else v-ripple class="clickable" @click="iconClick">
        <v-icon>{{ icon }}</v-icon>
      </div>
    </div>
    <!-- TODO fix the overflow here -->
    <h3 :title="header">{{ header }}</h3>
  </div>
</template>

<script>
import { NavigationStore } from "@/stores/NavigationStore";

export default {
  name: "PageHeader",
  components: {},
  methods: {
    togglePageCollapseExpand: function () {
      NavigationStore.isCollapsed = !NavigationStore.isCollapsed;
    },
  },
  data() {
    return {
      NavigationStore,
    };
  },
  props: {
    header: String,
    icon: String,
    iconClick: Function,
  },
};
</script>

<style scoped>
.page-container-header {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

@media only screen and (max-width: 600px) {
  .page-container-header {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
}

.page-container-header .nav-header-icon {
  padding-left: 16px;
  font-size: 1.4em;
  margin-top: -4px;
}

.page-container-header .clickable {
  cursor: pointer;
}

.page-container-header h3 {
  padding-left: 10px;
  font-size: 1.4em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.page-container-header .page-collapse {
  position: absolute;
  padding: 8px;
  right: 12px;
}
</style>
