import { reactive } from 'vue';
import _ from "lodash";

import { UserInfoStore } from '@/stores/UserInfoStore';

export const NavigationStore = reactive({
    adminRoutes: [
        'items.create',
        'items.company'
    ],
    changeListeners: {
    },
    isCollapsed: false,
    items: {
        create: false,
        settings: false,
        surveys: true,
        company: false,
    },
    mobileMapView: false,
    anyOpen() {
        return _.some(this.items, (v) => v === true);
    },
    closeItem(key) {
        if (_.has(this, key)) {
            _.set(this, key, false);
        }
    },
    getSelectedKey() {
        const key = _.findKey(this.items, (value) => value === true);
        return `items.${key}`;
    },
    isSelected(key) {
        return _.get(this, key, false);
    },
    registerChangeListener(name, callback) {
        this.changeListeners[name] = callback;
    },
    toggleItem(key) {
        if (_.has(this, key)) {
            Object.keys(this.items).forEach(k => {
                if (`items.${k}` !== key) {
                    this.items[k] = false;
                }
            });
            _.set(this, key, !_.get(this, key));
            _.forEach(this.changeListeners, (listener) => listener());
        }
    },
    shouldShow(key) {
        const isAdminRoute = _.includes(this.adminRoutes, key);
        if (isAdminRoute) {
            return UserInfoStore.admin;
        }
        return true;
    },
    unregisterChangeListener(name) {
        delete this.changeListeners[name];
    }
})