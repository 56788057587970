import { ConfigurationStore } from "../stores/ConfigurationStore.js";
import ApiService from "./ApiService.js";

export default class MapQueryService {

    static async queryByPolygon(latlon) {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/geo/polygon`, { method: 'POST', body: JSON.stringify(latlon), headers: { 'Content-Type': 'application/json' } });
        return await response.json();
    }

    static async queryByPoint(latlon) {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/geo/point`, { method: 'POST', body: JSON.stringify(latlon), headers: { 'Content-Type': 'application/json' } });
        return await response.json();
    }

    static async searchAddressByQuery(query) {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/geo/search?q=${encodeURIComponent(query)}`, { method: 'GET' });
        return await response.json();
    }
}