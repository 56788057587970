<template>
  <v-app v-if="isAuthenticated() && isInitialized">
    <NavigationRail v-if="!LayoutStore.isMobile()" />
    <NavigationBar v-if="LayoutStore.isMobile()" />
    <v-main class="main-container">
      <PageContainer
        :isOpen="NavigationStore.anyOpen()"
        :isCollapsed="NavigationStore.isCollapsed"
      >
        <template v-slot:content>
          <Transition name="fade" mode="out-in">
            <NewSurveyPage
              v-if="
                NavigationStore.items.create &&
                NavigationStore.shouldShow('items.create')
              "
            />
            <SettingsPage v-if="NavigationStore.items.settings" />
            <CompanySettingsPage
              v-if="
                NavigationStore.items.company &&
                NavigationStore.shouldShow('items.company')
              "
            />
            <SurveyListPage v-if="NavigationStore.items.surveys" />
          </Transition>
        </template>
      </PageContainer>

      <MapContainer
        :navWidth="NavigationStore.anyOpen() ? 480 : 0"
        v-if="LayoutStore.isMobile() === false"
      />
    </v-main>
  </v-app>
  <v-app v-else>
    <SplashScreen />
  </v-app>
</template>

<script>
import MapContainer from "@/components/MapContainer.vue";
import NavigationBar from "@/components/navigation/NavigationBar.vue";
import NavigationRail from "@/components/navigation/NavigationRail.vue";
import SplashScreen from "@/components/SplashScreen.vue";

import PageContainer from "@/components/pages/PageContainer.vue";
import NewSurveyPage from "@/components/pages/NewSurveyPage.vue";
import SettingsPage from "@/components/pages/SettingsPage.vue";
import CompanySettingsPage from "@/components/pages/company/CompanySettingsPage.vue";
import SurveyListPage from "@/components/pages/SurveyListPage.vue";

import { LayoutStore } from "@/stores/LayoutStore.js";
import { NavigationStore } from "@/stores/NavigationStore.js";
import { OrgInfoStore } from "@/stores/OrgInfoStore.js";
import { UserInfoStore } from "@/stores/UserInfoStore.js";

import AuthUtil from "@/util/AuthUtil.js";

export default {
  name: "App",

  components: {
    CompanySettingsPage,
    MapContainer,
    NavigationBar,
    NavigationRail,
    NewSurveyPage,
    PageContainer,
    SplashScreen,
    SettingsPage,
    SurveyListPage,
  },

  computed: {
    isInitialized: function () {
      return OrgInfoStore.isInitialized && UserInfoStore.isInitialized;
    },
  },

  data: () => ({
    LayoutStore,
    NavigationStore,
    UserInfoStore,
  }),
  methods: {
    isAuthenticated: function () {
      return AuthUtil.isAuthenticated();
    },
  },
  mounted() {
    // TODO fix this...
    if (!AuthUtil.isAuthenticated()) {
      AuthUtil.login();
    } else {
      UserInfoStore.fetch();
      OrgInfoStore.fetch();
    }
  },
};
</script>

<style scoped>
.main-container {
  position: absolute;
  width: calc(100% - 88px);
  height: 100%;
  left: 88px;
}

@media only screen and (max-width: 600px) {
  .main-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
