<template>
  <div>
    <v-data-table
      class="response-code-list-table"
      :headers="tableHeaders"
      :items="responseCodes"
      :mobile-breakpoint="0"
      :search="search"
    >
      <template #[`item.color`]="{ item }">
        <div
          class="response-code-color-icon"
          :style="`background: ${getResponseCodeColor(item)}`"
          @click="openColorDialog(item)"
        />
      </template>
      <template #[`item.actions`]="{ item }">
        <v-icon small @click="openDeleteDialog(item)"> mdi-delete </v-icon>
      </template>
      <template #[`footer.prepend`]>
        <v-btn
          text
          small
          @click="addDialog = true"
          class="response-code-add-button"
        >
          <v-icon> mdi-plus </v-icon>Add Response Code
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="colorDialog" max-width="320px">
      <v-card>
        <v-card-title class="text-h5">Set Color</v-card-title>
        <v-card-text>
          <v-progress-linear
            :active="isColorEditSaving"
            indeterminate
          ></v-progress-linear>
          <p>Choose the color for this response</p>
          <v-color-picker
            v-model="selectedColor"
            hide-canvas
            hide-inputs
            hide-sliders
            show-swatches
            swatches-max-height="500"
          ></v-color-picker>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="saveResponseCodeColor()"
            :disabled="isColorEditSaving"
          >
            Yes
          </v-btn>
          <v-btn color="blue darken-1" text @click="colorDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="320px">
      <v-card>
        <v-card-title class="text-h5">Delete Response Code</v-card-title>
        <v-card-text>
          Warning, this will delete:<br />
          <b>{{ selectedDeleteResponse }}</b
          ><br />Do you want to continue?
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="deleteResponseCode()">
            Yes
          </v-btn>
          <v-btn color="blue darken-1" text @click="deleteDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addDialog" max-width="320px">
      <v-card>
        <v-card-title class="text-h5">Add Response Code</v-card-title>
        <v-card-text>
          <v-text-field
            label="Abbreviation"
            v-model="addAbbreviation"
            counter
            maxlength="4"
          ></v-text-field>
          <v-text-field
            label="Description"
            v-model="addDescription"
          ></v-text-field>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="addResponseCode()">
            Add
          </v-btn>
          <v-btn color="blue darken-1" text @click="clearAddResponseCode()">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";

import OrganizationService from "@/services/OrganizationService";

import { OrgInfoStore } from "@/stores/OrgInfoStore";

const HEADERS = [
  { text: "Abbreviation", value: "abbreviation" },
  { text: "Description", value: "response" },
  { text: "Color", value: "color" },
  { text: "", value: "actions" },
];

export default {
  name: "ResponseCodeList",
  computed: {
    selectedDeleteResponse: function () {
      return `${_.get(this.deleteResponse, "response")} (${_.get(
        this.deleteResponse,
        "abbreviation"
      )})`;
    },
  },
  components: {},
  data: function () {
    return {
      addDialog: false,
      addAbbreviation: "",
      addDescription: "",
      deleteDialog: false,
      deleteResponse: {},
      options: {},
      search: "",
      tableHeaders: HEADERS,

      colorDialog: false,
      isColorEditSaving: false,
      selectedColor: "",
      selectedResponseForColorEdit: undefined,
    };
  },
  methods: {
    addResponseCode: function () {
      // this.addDialog = false;
      const toAdd = {
        code: {
          abbreviation: this.addAbbreviation,
          response: this.addDescription,
        },
      };
      this.clearAddResponseCode();
      OrganizationService.addResponseCode(toAdd).then((org) => {
        OrgInfoStore.responseCodes = org.responseCodes;
        OrgInfoStore.responseCodeColors = org.responseCodeColors;
      });
    },
    clearAddResponseCode: function () {
      this.addDialog = false;
      this.addAbbreviation = "";
      this.addDescription = "";
    },
    deleteResponseCode: function () {
      this.deleteDialog = false;
      OrganizationService.deleteResponseCode(this.deleteResponse).then(
        (org) => {
          OrgInfoStore.responseCodes = org.responseCodes;
          OrgInfoStore.responseCodeColors = org.responseCodeColors;
        }
      );
    },
    getResponseCodeColor: function (item) {
      return _.get(
        OrgInfoStore.responseCodeColors,
        item.abbreviation,
        "#FFFFFFFF"
      );
    },
    openColorDialog: function (item) {
      this.colorDialog = true;
      this.selectedColor = this.getResponseCodeColor(item);
      this.selectedResponseForColorEdit = item;
    },
    saveResponseCodeColor: function () {
      this.isColorEditSaving = true;
      const toUpdate = {
        code: {
          abbreviation: this.selectedResponseForColorEdit.abbreviation,
          response: this.selectedResponseForColorEdit.response,
        },
        color: this.selectedColor,
      };
      OrganizationService.addResponseCode(toUpdate).then((org) => {
        this.isColorEditSaving = false;
        OrgInfoStore.responseCodes = org.responseCodes;
        OrgInfoStore.responseCodeColors = org.responseCodeColors;
        this.colorDialog = false;
      });
    },
    openDeleteDialog: function (item) {
      this.deleteResponse = item;
      this.deleteDialog = true;
    },
  },
  mounted() {},
  props: {
    responseCodes: Array,
  },
  watch: {},
};
</script>

<style>
.response-code-list-table .v-data-footer {
  justify-content: right;
  padding: 10px;
}

.response-code-list-table .v-data-footer__select {
  display: none;
}

.response-code-add-button {
  margin-right: auto;
}

.response-code-color-icon {
  height: 24px;
  width: 24px;
  border: 1px solid #8d8d8d;
  border-radius: 12px;
  cursor: pointer;
}

.response-code-color-icon:hover {
  border: 1px solid #4a4a4a;
}
</style>
