<template>
  <v-list two-line>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ survey.name }}</v-list-item-title>
        <v-list-item-subtitle>Name</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{
          getSurveyPlaceDetailString(survey)
        }}</v-list-item-title>
        <v-list-item-subtitle>Places</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ survey.surveyors.length }}</v-list-item-title>
        <v-list-item-subtitle>Surveyors</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{
          new Date(survey.createTime).toLocaleString()
        }}</v-list-item-title>
        <v-list-item-subtitle>Created Date</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import _ from "lodash";

const COMPONENT_NAME = "SurveyOverview";

export default {
  name: COMPONENT_NAME,
  computed: {},
  components: {},
  data: function () {
    return {};
  },
  methods: {
    getSurveyPlaceDetail: function (survey) {
      const numberOfPlaces = Object.keys(survey.places).length;
      if (numberOfPlaces === 0) {
        return {
          percent: 100,
          noResponses: 0,
          total: 0,
        };
      }

      const noResponses = _.filter(
        survey.places,
        (place) => place.responseCode.abbreviation === "NR"
      ).length;
      return {
        percent: (
          ((numberOfPlaces - noResponses) / numberOfPlaces) *
          100
        ).toFixed(2),
        noResponses: noResponses,
        total: numberOfPlaces,
      };
    },
    getSurveyPlaceDetailString: function (survey) {
      const placeDetail = this.getSurveyPlaceDetail(survey);
      return `${placeDetail.total - placeDetail.noResponses} / ${
        placeDetail.total
      } (${placeDetail.percent}%)`;
    },
  },
  mounted() {},
  props: {
    survey: Object,
  },
  watch: {},
};
</script>

<style>
</style>
