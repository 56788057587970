import { ConfigurationStore } from "../stores/ConfigurationStore.js";
import ApiService from "./ApiService.js";

export default class OrganizationService {

    static async getOrganization() {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/organization`, { method: 'GET' });
        return await response.json();
    }

    static async addResponseCode(body) {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/organization/code`, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' } });
        return await response.json();
    }

    static async deleteResponseCode(body) {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/organization/code`, { method: 'DELETE', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' } });
        return await response.json();
    }

    static async getPlaceMetadata(placeId) {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/organization/metadata/place/${placeId}`, { method: 'GET' });
        return await response.json();
    }

    static async updatePlaceMetadata(placeId, update) {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/organization/metadata/place/${placeId}`, { method: 'POST', body: JSON.stringify(update), headers: { 'Content-Type': 'application/json' } });
        return await response.json();
    }
}