var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isAuthenticated() && _vm.isInitialized)?_c('v-app',[(!_vm.LayoutStore.isMobile())?_c('NavigationRail'):_vm._e(),(_vm.LayoutStore.isMobile())?_c('NavigationBar'):_vm._e(),_c('v-main',{staticClass:"main-container"},[_c('PageContainer',{attrs:{"isOpen":_vm.NavigationStore.anyOpen(),"isCollapsed":_vm.NavigationStore.isCollapsed},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('Transition',{attrs:{"name":"fade","mode":"out-in"}},[(
              _vm.NavigationStore.items.create &&
              _vm.NavigationStore.shouldShow('items.create')
            )?_c('NewSurveyPage'):_vm._e(),(_vm.NavigationStore.items.settings)?_c('SettingsPage'):_vm._e(),(
              _vm.NavigationStore.items.company &&
              _vm.NavigationStore.shouldShow('items.company')
            )?_c('CompanySettingsPage'):_vm._e(),(_vm.NavigationStore.items.surveys)?_c('SurveyListPage'):_vm._e()],1)]},proxy:true}],null,false,1478448792)}),(_vm.LayoutStore.isMobile() === false)?_c('MapContainer',{attrs:{"navWidth":_vm.NavigationStore.anyOpen() ? 480 : 0}}):_vm._e()],1)],1):_c('v-app',[_c('SplashScreen')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }