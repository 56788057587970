import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import L from "leaflet";
import { msalPlugin } from "./plugins/msalPlugin"
import { msalInstance } from "./authConfig"

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.use(msalPlugin, msalInstance)

new Vue({
    vuetify,
    render: h => h(App)
}).$mount('#app')
