import _ from "lodash";
import L from "leaflet";
import ColorUtil from "@/util/ColorUtil";
import { OrgInfoStore } from "@/stores/OrgInfoStore";

export default class PlaceDetail {

    initialize(options) {
        this.createPolygon(options);
        this.createMarker(options);
        this.isSelected = false;
    }

    setMarkerMapCallback(func) {
        this.markerAdd = func.add;
        this.markerRemove = func.remove;
        if (!this.isMarkerVisible()) {
            this.currentMarkerVisibility = false;
            return;
        }
        this.currentMarkerVisibility = true;
        this.markerAdd(this.marker);
    }

    createMarker(options) {
        if (this.marker) {
            return;
        }

        const location = this.getMarkerLocationOverride() === undefined ? this.polygon.getBounds().getCenter() : this.getMarkerLocationOverride();
        const marker = L.marker(location, {
            icon: L.divIcon({
                className: "parcel-address",
                html: this.getMarkerHtml(),
            }),
            zIndexOffset: 1000,
        });

        options = options === undefined ? {} : options;
        if (options.onClick) {
            marker.on("click", () => options.onClick(this.mapObject));
        }
        if (options.onMouseOver) {
            marker.on("mouseover", () => options.onMouseOver(this.mapObject));
        }
        if (options.onMouseOut) {
            marker.on("mouseout", () => options.onMouseOut(this.mapObject));
        }

        this.marker = marker;
    }

    setMarkerEditMode(enabled, callback) {
        this.isInEditMode = enabled;
        this.updateMarker();

        if (this.currentMarkerVisibility === false) {
            console.warn("Attempting to change marker edit mode on a marker that is not visible");
            return;
        }

        this.marker.dragging.disable();
        this.marker.off("dragend");
        if (enabled) {
            this.marker.dragging.enable();
            this.marker.on("dragend", (event) => callback(event));
        }
    }

    setPolygonMapCallback(func) {
        this.polygonAdd = func.add;
        this.polygonRemove = func.remove;
        if (this.isSubunit()) {
            return;
        }
        this.polygonAdd(this.polygon);
    }

    createPolygon(options) {
        if (this.polygon) {
            return;
        }

        const outline = this.mapObject.bounds.map(bound => [bound.lat, bound.lon]);
        const polygon = L.polygon(outline, { color: "#757575", fillColor: this.getPolygonFill(), fill: true, fillOpacity: 1, weight: 1 });

        if (_.get(options, "onClick")) {
            polygon.on("click", () => options.onClick(this.mapObject));
        }
        polygon.on("mouseover", () => this.polygonMouseOver());
        polygon.on("mouseout", () => this.polygonMouseOut());

        this.polygon = polygon;
    }

    updatePolygon() {
        if (this.polygon) {
            this.polygon.setStyle({
                fillColor: this.getPolygonFill(),
            });
        }
    }

    polygonMouseOver() {
        if (this.polygon) {
            this.polygon.setStyle({
                fillColor: ColorUtil.adjust(this.getPolygonFill(), -20),
            });
        }
    }

    polygonMouseOut() {
        if (this.polygon) {
            this.polygon.setStyle({
                fillColor: this.getPolygonFill(),
            });
        }
    }

    selectPlace() {
        this.isSelected = true;
        this.updateMarker();
    }

    deselectPlace() {
        this.isSelected = false;
        this.updateMarker();
    }

    getId() {
        return _.get(this, "mapObject.id");
    }

    getPlaceLabel() {
        const overrideLabel = _.get(this, "overrides.label");
        if (overrideLabel !== undefined && overrideLabel !== null) {
            return overrideLabel;
        }
        return _.get(this, "mapObject.address.number", "N/A");
    }

    getResponseCode() {
        return _.get(this, "responseCode.abbreviation", "NR");
    }

    setResponseCode(responseCode) {
        _.set(this, "responseCode", responseCode);
        this.updateMarker();
        this.updatePolygon();
    }

    updateMarker() {
        this.marker.setIcon(L.divIcon({
            className: "parcel-address",
            html: this.getMarkerHtml(),
        }));
    }

    getMarkerDivClass() {
        let markerClass = "";
        if (this.isInEditMode === true) {
            markerClass += "parcel-address-edit-mode ";
        }
        if (this.isSelected === true) {
            markerClass += "parcel-address-selected";
        }
        return markerClass;
    }

    getMarkerHtml() {
        // let style = `style="color:${PlaceUtil.responseToColor(responseAbbreviation)};"`
        const style = `style=""`
        const classValue = `class="${this.getMarkerDivClass()}"`;
        return `<div ${style} ${classValue}><b>${this.getPlaceLabel()}</b><br /> ${this.getResponseCode()}</div>`;
    }

    getMarkerLocation() {
        if (this.getMarkerLocationOverride()) {
            return this.getMarkerLocationOverride();
        }
        return this.marker.getLatLng();
    }

    getMarkerLocationOverride() {
        const override = _.get(this, "overrides.markerLocation");
        if (override !== undefined) {
            return JSON.parse(override);
        }
        return undefined;
    }

    getPolygonFill() {
        return _.get(OrgInfoStore.responseCodeColors, this.getResponseCode(), "#BDBDBD");
    }

    isMarkerVisible() {
        let override = _.get(this, "overrides.markerVisible");
        if (override !== undefined) {
            return override === "true";
        }
        return !this.isSubunit();
    }

    setMarkerVisibility(visible) {
        this.updateMarker();
        if (visible === true && this.currentMarkerVisibility === false) {
            this.markerAdd(this.marker);
            this.currentMarkerVisibility = true;
        } else if (visible === false && this.currentMarkerVisibility === true) {
            this.markerRemove(this.marker);
            this.currentMarkerVisibility = false;
        }
    }

    isSubunit() {
        return _.get(this, "parentId") !== null;
    }

    static from(json, options) {
        const placeDetail = Object.assign(new PlaceDetail(), json);
        placeDetail.initialize(options)
        return placeDetail;
    }
}