<template>
  <div>
    <div class="place-select-controls">
      <v-btn-toggle
        v-model="placeSelectMode"
        borderless
        @change="areaSelectChanged"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small v-bind="attrs" v-on="on">
              <v-icon left> mdi-select-drag </v-icon>
              <span>Area Select</span>
            </v-btn>
          </template>
          <span>Draw area on map to select</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small v-bind="attrs" v-on="on">
              <v-icon left> mdi-cursor-default-click </v-icon>
              <span>Click Select</span>
            </v-btn>
          </template>
          <span>Click place on map to select</span>
        </v-tooltip>
      </v-btn-toggle>
    </div>
    <v-list
      dense
      v-if="Object.keys(SurveyCreationStore.placesInternal).length > 0"
    >
      <v-subheader>Selected Places</v-subheader>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
        class="place-select-address"
      >
        <v-list-item
          v-for="(place, i) in SurveyCreationStore.placesInternal"
          :key="i"
          @mouseover="placeMouseOver(place)"
          @mouseout="placeMouseOut(place)"
          @click="openPlaceEditDialog(place)"
        >
          <v-list-item-content>
            <v-list-item-title
              v-text="place.address.addressLine1"
            ></v-list-item-title>
            <v-list-item-subtitle
              v-text="place.address.addressLine2"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-dialog v-model="placeEditToolDialog" max-width="300px">
      <PlaceEditTool
        :cancelCallback="clearPlaceEditDialog"
        :deleteCallback="deletePlaceFromEdit"
        :place="placeToEdit"
        :saveCallback="savePlaceEdit"
      />
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import Vue from "vue";

import PlaceEditTool from "@/components/tools/PlaceEditTool";

import { ConfigurationStore } from "@/stores/ConfigurationStore.js";
import { MapDataStore } from "@/stores/MapDataStore.js";
import { SurveyCreationStore } from "@/stores/SurveyCreationStore.js";

import MapQueryService from "@/services/MapQueryService";

import ColorUtil from "@/util/ColorUtil.js";
import LeafletUtil from "@/util/LeafletUtil";

const COMPONENT_NAME = "SelectPlaces";
const COMPONENT_MARKER_LAYER = `${COMPONENT_NAME}-Markers`;

const PLACE_SELECT_MODES = {
  area: {
    enabledCallback: () => MapDataStore.methods.enablePolygonDraw(),
    disabledCallback: () => MapDataStore.methods.disablePolygonDraw(),
    value: 0,
    snackbarIcon: "mdi-select-drag",
    snackbarText: "Select an area on the map to add to this survey",
  },
  click: {
    value: 1,
    snackbarIcon: "mdi-cursor-default-click",
    snackbarText: "Click an area on the map to add to this survey",
  },
};

export default {
  name: COMPONENT_NAME,
  components: {
    PlaceEditTool,
  },
  data: function () {
    return {
      escapeKeyListener: undefined,
      placeEditToolDialog: false,
      placeToEdit: undefined,
      placeSelectMode: undefined,
      places: {},
      selectedItem: "",
      SurveyCreationStore,
    };
  },
  methods: {
    areaSelectChanged: function (selectedMode) {
      this.placeSelectMode = selectedMode;
      if (selectedMode !== undefined) {
        const mode = _.find(
          PLACE_SELECT_MODES,
          (mode) => mode.value === selectedMode
        );
        if (mode) {
          MapDataStore.methods.setSnackbar(
            mode.snackbarText,
            mode.snackbarIcon
          );
          if (mode.enabledCallback) {
            mode.enabledCallback();
          }
        }
      } else {
        MapDataStore.methods.clearSnackbar();
      }

      // call the disable callback for each mode that is:
      // 1) not selected
      // 2) has disalbedCallback specified
      _.forEach(PLACE_SELECT_MODES, (mode) => {
        if (mode.value !== selectedMode && mode.disabledCallback) {
          mode.disabledCallback();
        }
      });
    },
    clearPlaceEditDialog: function () {
      this.placeEditToolDialog = false;
      this.placeToEdit = undefined;
    },
    deletePlaceFromEdit: function () {
      this.removePlaceFromSurvey(this.placeToEdit);
      this.clearPlaceEditDialog();
    },
    drawPlaceOnMap: function (obj) {
      if (SurveyCreationStore.placesInternal[obj.location]) {
        return;
      }

      Vue.set(SurveyCreationStore.placesInternal, obj.location, obj);

      MapDataStore.methods.addLayer(COMPONENT_NAME, obj.polygon);
      MapDataStore.methods.addLayer(
        COMPONENT_MARKER_LAYER,
        obj.marker,
        ConfigurationStore.map.textZoomLevel
      );
    },
    getPolygonAndMarker: function (places) {
      return LeafletUtil.mapObjectsToPolygonAndMarker(places, {
        onClick: (place) => {
          this.openPlaceEditDialog(place);
        },
        onMouseOver: (place) => {
          this.placeMouseOver(place);
        },
        onMouseOut: (place) => {
          this.placeMouseOut(place);
        },
      });
    },
    openPlaceEditDialog: function (place) {
      this.placeEditToolDialog = true;
      this.placeToEdit = place;
    },
    placeMouseOver: function (place) {
      place.polygon.setStyle({ fillColor: ColorUtil.adjust("#FFFFFF", -30) });
    },
    placeMouseOut: function (place) {
      place.polygon.setStyle({ fillColor: "#FFFFFF" });
    },
    savePlaceEdit: function (edit) {
      if (edit.label) {
        if (
          SurveyCreationStore.placesInternal[this.placeToEdit.location] &&
          SurveyCreationStore.placesInternal[this.placeToEdit.location].marker
        ) {
          SurveyCreationStore.placesInternal[
            this.placeToEdit.location
          ].marker.updateText(edit.label);
          SurveyCreationStore.placesInternal[
            this.placeToEdit.location
          ].markerLabel = edit.label;
        }
      }
      this.clearPlaceEditDialog();
    },
    removePlaceFromSurvey: function (place) {
      Vue.delete(SurveyCreationStore.placesInternal, place.location);
      place.marker.removeFrom(MapDataStore.map);
      place.polygon.removeFrom(MapDataStore.map);
    },
  },
  mounted() {
    // TODO remove event listeners once component is unmounted
    MapDataStore.callbacks.click[COMPONENT_NAME] = (e, map) => {
      if (this.placeSelectMode !== PLACE_SELECT_MODES.click.value) {
        return;
      }
      MapQueryService.queryByPoint(
        LeafletUtil.pointToLatLonJson(e.latlng)
      ).then((res) => {
        this.getPolygonAndMarker([res]).forEach((obj) => {
          this.drawPlaceOnMap(obj, map);
        });
      });
    };

    MapDataStore.callbacks.polygonSelect[COMPONENT_NAME] = (e, map) => {
      if (this.placeSelectMode !== PLACE_SELECT_MODES.area.value) {
        return;
      }
      MapQueryService.queryByPolygon(
        LeafletUtil.latitudeLongitudeToJson(e.layer.getLatLngs())
      ).then((res) => {
        this.getPolygonAndMarker(res).forEach((obj) => {
          this.drawPlaceOnMap(obj, map);
        });
      });
    };

    // listen for escape key to disable polygon select mode (if currently selected)
    this.escapeKeyListener = window.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        this.areaSelectChanged(undefined);
      }
    });
  },
  props: {
    step: [Number, String],
  },
  watch: {
    step: function (newVal) {
      // TODO clean this up... really need to figure out unmount
      if (newVal !== 2) {
        this.areaSelectChanged(undefined);

        // set the places field in the SurveyCreationStore
        SurveyCreationStore.places = {};
        _.forEach(SurveyCreationStore.placesInternal, (value) => {
          SurveyCreationStore.places[value.id] = {
            label: _.get(value, "markerLabel", null),
          };
        });
      }
    },
  },
};
</script>

<style scoped>
.place-select-controls {
  padding: 4px 0px 10px 0px;
}

.place-select-address {
  max-height: 40vh;
  overflow: auto;
}

.place-select-list-delete-icon {
  margin-top: 12px !important;
}
</style>
