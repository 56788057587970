<template>
  <div>
    <v-progress-linear
      :active="labelEditSaving"
      indeterminate
    ></v-progress-linear>
    <v-expansion-panel-content>
      <div class="place-label-edit-form">
        <span
          >Use the options below to configure the visibility and position of
          this address on the map</span
        >
        <v-checkbox
          @change="toggleLabelVisiblity"
          label="Show on Map"
          :value="mapLabelVisibleControl"
        ></v-checkbox>
        <v-btn-toggle :value="labelMoveMode">
          <v-btn
            small
            @click="toggleLabelMoveMode"
            :disabled="mapLabelVisibleControl === false"
          >
            <v-icon left> mdi-cursor-move </v-icon>
            <span>Move Label</span>
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="place-detail-action-buttons">
        <v-btn
          color="primary"
          :value="labelMoveMode"
          :disabled="saveDisabled"
          @click="saveLabelEdit()"
        >
          Save
        </v-btn>
        <v-btn color="grey" @click="resetLabelState()"> Reset </v-btn>
      </div>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import _ from "lodash";

import { SurveyStore } from "@/stores/SurveyStore.js";
import SurveyService from "@/services/SurveyService";

export default {
  name: "PlaceLabelEditForm",
  computed: {
    saveDisabled: function () {
      const labelChanged =
        this.labelMoveLatLon !== undefined ||
        this.mapLabelVisibleControl !== this.initialVisiblity;
      return labelChanged === false || this.labelEditSaving === true;
    },
  },
  components: {},
  data: function () {
    return {
      labelMoveMode: undefined,
      labelMoveLatLon: undefined,

      mapLabelVisibleControl: false,
      labelEditSaving: false,

      initialVisiblity: false,
      initialLocation: undefined,

      place: undefined,

      SurveyStore,
    };
  },
  methods: {
    dragendCallback: function (event) {
      this.labelMoveLatLon = event.target.getLatLng();
    },
    enterEditMode: function () {
      this.labelMoveMode = 0;
      this.place.setMarkerEditMode(true, this.dragendCallback);
    },
    leaveEditMode: function () {
      this.labelMoveMode = undefined;
      this.place.setMarkerEditMode(false);
    },
    toggleLabelMoveMode: function () {
      if (this.labelMoveMode === undefined) {
        this.enterEditMode();
      } else {
        this.leaveEditMode();
      }
    },
    toggleLabelVisiblity: function () {
      this.mapLabelVisibleControl = !this.mapLabelVisibleControl;
      if (this.mapLabelVisibleControl === true) {
        this.place.setMarkerVisibility(true);
      } else {
        this.place.setMarkerVisibility(false);
      }
    },

    setState: function (placeId) {
      this.place = _.get(SurveyStore.survey, `places.${placeId}`);
      this.initialVisiblity = this.place.isMarkerVisible();
      this.initialLocation = this.place.getMarkerLocation();
      this.mapLabelVisibleControl = this.initialVisiblity;
    },

    saveLabelEdit: function () {
      const placeEdit = {
        markerVisible: this.mapLabelVisibleControl,
        markerLocation:
          this.labelMoveLatLon === undefined
            ? ""
            : JSON.stringify(this.labelMoveLatLon),
      };
      this.labelEditSaving = true;
      SurveyService.addPlaceOverride(
        SurveyStore.survey.id,
        SurveyStore.selectedPlaceId,
        placeEdit
      ).then((response) => {
        const overrides = _.get(
          response,
          `places.${this.place.getId()}.overrides`
        );
        this.place.overrides = overrides;

        this.leaveEditMode();
        this.labelEditSaving = false;
        this.initialVisiblity = this.place.isMarkerVisible();
        this.initialLocation = this.place.getMarkerLocation();
        this.labelMoveLatLon = undefined;
      });
    },
    resetLabelState: function () {
      this.place.setMarkerVisibility(this.initialVisiblity);
      this.leaveEditMode();
      this.mapLabelVisibleControl = this.initialVisiblity;
      this.place.marker.setLatLng(this.initialLocation);
      this.labelMoveLatLon = undefined;
    },
  },
  mounted() {
    this.setState(SurveyStore.selectedPlaceId);
  },
  props: {},
  watch: {
    "SurveyStore.selectedPlaceId"(newId) {
      this.resetLabelState();
      this.setState(newId);
    },
  },
};
</script>

<style>
.place-label-edit-form {
  padding-bottom: 20px;
}

.place-label-edit-form > span {
  font-size: 0.9em;
}

.place-detail-action-buttons > button:nth-child(2) {
  float: right;
}

.place-detail-action-buttons.top-padding {
  padding-top: 24px;
}
</style>
