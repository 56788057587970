<template>
  <div>
    <div class="surveyor-select-controls">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            small
            @click="surveyorSelectDialog = true"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left> mdi-account-multiple-plus </v-icon>
            <span>Add Surveyors</span>
          </v-btn>
        </template>
        <span>Add Surveyors</span>
      </v-tooltip>
    </div>
    <v-list dense v-if="Object.keys(SurveyCreationStore.surveyors).length > 0">
      <v-subheader>Surveyors</v-subheader>
      <v-list-item-group color="primary" class="surveyor-select-address">
        <v-list-item
          v-for="(surveyor, i) in SurveyCreationStore.surveyors"
          :key="i"
        >
          <v-list-item-content>
            <v-list-item-title
              v-text="getFullName(surveyor)"
            ></v-list-item-title>
            <v-list-item-subtitle
              v-text="surveyor.email"
            ></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon @click="removeSurveyor(surveyor)">
            <v-icon>mdi-delete</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-dialog v-model="surveyorSelectDialog" max-width="300px">
      <SurveyorSearchTool :addSurveyors="addSurveyors" />
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";

import SurveyorSearchTool from "@/components/tools/SurveyorSearchTool";

import { SurveyCreationStore } from "@/stores/SurveyCreationStore.js";

export default {
  name: "SurveyorSelectTool",
  components: {
    SurveyorSearchTool,
  },
  data: function () {
    return {
      SurveyCreationStore,
      surveyorSelectDialog: false,
    };
  },
  methods: {
    addSurveyors: function (surveyors) {
      _.forEach(surveyors, (surveyor, id) => {
        Vue.set(SurveyCreationStore.surveyors, id, surveyor);
      });
      this.surveyorSelectDialog = false;
    },
    getFullName: function (surveyor) {
      return _.startCase(surveyor.fullName);
    },
    removeSurveyor: function (surveyor) {
      Vue.delete(SurveyCreationStore.surveyors, surveyor.userId);
    },
  },
  mounted() {},
  props: {},
  watch: {},
};
</script>

<style scoped>
.surveyor-select-controls {
  padding: 4px 0px 10px 0px;
}

.surveyor-select-address {
  max-height: 40vh;
  overflow: auto;
}

.surveyor-select-list-delete-icon {
  margin-top: 12px !important;
}
</style>
