import { ConfigurationStore } from "../stores/ConfigurationStore.js";
import ApiService from "./ApiService.js";

export default class UserService {

    static async getMyUserInfo() {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/user/me`, { method: 'GET' });
        return await response.json();
    }

    static async getSurveyors(page, pageSize, query, sortField, sortOrder) {
        const endpoint = `${ConfigurationStore.backend}/api/user/surveyors?` + new URLSearchParams({
            p: page,
            ps: pageSize,
            q: query,
            s: sortOrder,
            sf: sortField
        });

        const response = await ApiService.makeApiCall(endpoint, { method: 'GET' });
        return await response.json();
    }

    static async getSurveyorsInSurvey(ids) {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/user/surveyors`, { method: 'POST', body: JSON.stringify(ids), headers: { 'Content-Type': 'application/json' } });
        return await response.json();
    }

    static async getInvites(page, pageSize, query, sortField, sortOrder) {
        const endpoint = `${ConfigurationStore.backend}/api/user/invites?` + new URLSearchParams({
            p: page,
            ps: pageSize,
            q: query,
            s: sortOrder,
            sf: sortField
        });

        const response = await ApiService.makeApiCall(endpoint, { method: 'GET' });
        return await response.json();
    }

    static async addInvite(email) {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/user/invite`, { method: 'POST', body: JSON.stringify(email), headers: { 'Content-Type': 'application/json' } });
        return await response.text();
    }

    static async deleteInvite(inviteId) {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/user/invite/${inviteId}`, { method: 'DELETE', headers: { 'Content-Type': 'application/json' } });
        return await response.text();
    }
}