<template>
  <div class="settings-page">
    <PageHeader header="Company" icon="mdi-account-group-outline" />
    <PageContent>
      <div class="settings-detail">
        <v-expansion-panels v-model="selectedPanel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="settings-detail-expansion-header"
              ><v-icon left> mdi-office-building-outline </v-icon>
              <h3>Company Info</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <div>
                <span><b>Name:</b> {{ OrgInfoStore.name }}</span>
              </div>
              <div>
                <span><b>ID:</b> {{ OrgInfoStore.id }}</span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="settings-detail-expansion-header"
              ><v-icon left> mdi-account-multiple </v-icon>
              <h3>Surveyors</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content
              class="settings-detail-content no-padding"
            >
              <SurveyorsList />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="settings-detail-expansion-header"
              ><v-icon left> mdi-account-multiple-plus </v-icon>
              <h3>Invites</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content
              class="settings-detail-content no-padding"
            >
              <InviteList />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="settings-detail-expansion-header"
              ><v-icon left> mdi-code-tags-check </v-icon>
              <h3>Response Codes</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content
              class="settings-detail-content no-padding"
            >
              <ResponseCodeList :responseCodes="OrgInfoStore.responseCodes" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </PageContent>
  </div>
</template>

<script>
import PageContent from "@/components/pages/PageContent.vue";
import PageHeader from "@/components/pages/PageHeader.vue";

import InviteList from "@/components/pages/company/InviteList.vue";
import ResponseCodeList from "@/components/pages/company/ResponseCodeList.vue";
import SurveyorsList from "@/components/pages/company/SurveyorsList.vue";

import { OrgInfoStore } from "@/stores/OrgInfoStore.js";
import { UserInfoStore } from "@/stores/UserInfoStore.js";

import AuthUtil from "@/util/AuthUtil";

export default {
  name: "CompanySettingsPage",
  components: {
    InviteList,
    PageContent,
    PageHeader,
    ResponseCodeList,
    SurveyorsList,
  },
  data() {
    return {
      selectedPanel: [0],
      OrgInfoStore,
      UserInfoStore,
    };
  },
  methods: {
    signout: function () {
      AuthUtil.logout();
    },
  },
  mounted() {},
  props: {},
};
</script>

<style>
.settings-page {
  height: 100%;
}

.settings-page .v-expansion-panel {
  border-radius: 0px !important;
}

.settings-page .v-expansion-panel::before {
  box-shadow: 0px 1px 0px 0px rgb(0 0 0 / 14%),
    0px -1px 0px 0px rgb(0 0 0 / 14%);
}

.settings-detail {
  padding-bottom: 30px;
}

.settings-detail-expansion-header > i {
  justify-content: left !important;
  flex: none !important;
}

.settings-detail-expansion-header > h3 {
  text-transform: capitalize;
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
  transition: none;
}

.settings-detail-content.no-padding > div:first-child {
  padding: 0px;
}

.dialog-subheader {
  padding: 0 24px 12px !important;
}
</style>
