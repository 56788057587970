import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { ConfigurationStore } from './stores/ConfigurationStore';

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: ConfigurationStore.auth.clientId,
        authority: ConfigurationStore.auth.authority,
        knownAuthorities: ConfigurationStore.auth.knownAuthorities,
        redirectUri: ConfigurationStore.auth.redirectUri,
    },
    cache: {
        cacheLocation: 'localStorage'
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
            logLevel: LogLevel.Verbose
        }
    }
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
    scopes: ConfigurationStore.auth.scopes,
};