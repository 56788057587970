import _ from "lodash";

import AuthUtil from "@/util/AuthUtil";

export default class ApiService {

    static async makeApiCall(endpoint, init) {
        // TODO don't make calls to admin endpoint if not an admin...
        // TODO handle error
        try {
            const auth = await AuthUtil.getToken();
            const token = _.get(auth, "accessToken");

            if (auth !== undefined && token !== undefined) {
                _.set(init, "headers.Authorization", `Bearer ${token}`);
                const response = await fetch(endpoint, init);
                return response;
            } else {
                // TODO handle error
                console.error("Could not fetch token");
            }
        } catch (e) {
            // TODO better error handling
            console.error(e);
        }

    }
}