import { reactive } from "vue";

import _ from "lodash";

import { MapDataStore } from "@/stores/MapDataStore.js";
import { NavigationStore } from "@/stores/NavigationStore.js";
import { SurveyListPageLayer, SurveyListPageMarkerLayer } from "@/components/pages/SurveyListPage.vue"

import SurveyService from "@/services/SurveyService";

export const SurveyPages = {
    LIST: "survey-list",
    DETAIL: "survey-detail",
    MAP: "map-view",
    PLACE_DETAIL: "place-detail",
}

export const SurveyStore = reactive({
    backButton: () => {
        if (SurveyStore.currentPage === SurveyPages.PLACE_DETAIL) {
            if (SurveyStore.selectedPlace) {
                SurveyStore.selectedPlace.deselectPlace();
            }
            SurveyStore.selectedPlaceId = undefined;
            SurveyStore.placeResponse = {};
            SurveyStore.selectedPlace = undefined;
        }

        SurveyStore.isSurveyLoading = false;
        SurveyStore.currentPage = SurveyStore.pageHistory.pop();

        if (SurveyStore.currentPage === SurveyPages.LIST) {
            SurveyStore.survey = undefined;
            SurveyStore.places = {};
            MapDataStore.methods.clearLayer(SurveyListPageLayer);
            MapDataStore.methods.clearLayer(SurveyListPageMarkerLayer);
            SurveyStore.fetchSurveys();
        }
        if (SurveyStore.currentPage === SurveyPages.DETAIL) {
            NavigationStore.mobileMapView = false;
        }
    },
    navigateToPage: (page) => {
        if (page === SurveyStore.currentPage) {
            return;
        }

        SurveyStore.pageHistory.push(SurveyStore.currentPage);
        SurveyStore.currentPage = page;
    },
    openPlacePage: function (placeId) {
        if (SurveyStore.selectedPlace !== undefined) {
            SurveyStore.selectedPlace.deselectPlace();
        }

        SurveyStore.selectedPlaceId = placeId;
        SurveyStore.navigateToPage(SurveyPages.PLACE_DETAIL);
        SurveyStore.selectedPlace = _.get(
            SurveyStore.survey,
            `places.${placeId}`
        );
        SurveyStore.selectedPlace.selectPlace();
    },
    currentPage: SurveyPages.LIST,
    pageHistory: [],

    clearState: () => {
        SurveyStore.currentPage = SurveyPages.LIST;
        SurveyStore.pageHistory = [];
        SurveyStore.isSurveyListLoading = false;
        SurveyStore.surveyList = [];
        SurveyStore.surveyListOptions = {};
        SurveyStore.surveySearchTerm = "";
        SurveyStore.totalSurveys = 0;
        SurveyStore.isSurveyLoading = false;
        SurveyStore.survey = undefined;
        SurveyStore.surveyRev = 0;
        SurveyStore.places = {};
        SurveyStore.placeAttempts = [];
        SurveyStore.placeResponse = {};
        SurveyStore.selectedPlaceId = undefined;
        SurveyStore.selectedPlace = undefined;
    },

    fetchSurveys: () => {
        const pageNumber = SurveyStore.surveyListOptions.page;
        const pageSize = SurveyStore.surveyListOptions.itemsPerPage;
        const sortField = _.get(SurveyStore.surveyListOptions, "sortBy[0]", "");
        const sortOrder = _.get(SurveyStore.surveyListOptions, "sortDesc[0]", false)
            ? "desc"
            : "asc";
        SurveyStore.isSurveyListLoading = true;
        SurveyService.getSurveys(
            pageNumber - 1,
            pageSize,
            SurveyStore.surveySearchTerm,
            sortField,
            sortOrder,
            false
        ).then((response) => {
            _.forEach(
                response.results,
                (survey) => (survey.percent = (survey.percent * 100).toFixed(1))
            );
            SurveyStore.surveyList = response.results;
            SurveyStore.totalSurveys = response.totalResults;
            SurveyStore.isSurveyListLoading = false;
        });
    },
    isSurveyListLoading: false,
    surveyList: [],
    surveyListOptions: {},
    surveySearchTerm: "",
    totalSurveys: 0,

    isSurveyLoading: false,
    survey: undefined,
    surveyRev: 0,

    places: {},
    placeAttempts: [],
    placeResponse: {},
    selectedPlaceId: undefined,
    selectedPlace: undefined,
});