<template>
  <div>
    <v-progress-linear
      :active="subunitLoading"
      indeterminate
    ></v-progress-linear>
    <v-data-table
      class="place-subunit-list-table"
      :headers="tableHeaders"
      :items="subunits"
      :mobile-breakpoint="0"
      :search="search"
      @click:row="openSubunitPage"
      no-data-text="No other address"
    >
      <template #[`item.actions`]="{ item }">
        <v-icon small @click="openDeleteDialog(item)"> mdi-delete </v-icon>
      </template>
      <template #[`footer.prepend`]>
        <v-btn
          text
          small
          @click="addDialog = true"
          class="place-subunit-add-button"
        >
          <v-icon> mdi-plus </v-icon>Add Another Address
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="deleteDialog" max-width="320px">
      <v-card>
        <v-card-title class="text-h5">Delete Response Code</v-card-title>
        <v-card-text>
          Warning, this will delete:<br />
          <b>{{ selectedDeleteResponse }}</b
          ><br />Do you want to continue?
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="deleteSubunit()">
            Yes
          </v-btn>
          <v-btn color="blue darken-1" text @click="deleteDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addDialog" max-width="320px">
      <v-card>
        <v-card-title class="text-h5">Add Another Address</v-card-title>
        <v-card-text>
          <v-text-field
            label="Street Number"
            v-model="addressToAdd"
            counter
            maxlength="10"
          ></v-text-field>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="addSubunit()"> Add </v-btn>
          <v-btn color="blue darken-1" text @click="clearAddDialog()">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";

import { OrgInfoStore } from "@/stores/OrgInfoStore";
import { SurveyStore } from "@/stores/SurveyStore";

import OrganizationService from "@/services/OrganizationService";
import SurveyService from "@/services/SurveyService";
import PlaceDetail from "@/model/place/PlaceDetail";
import { MapDataStore } from "@/stores/MapDataStore";
import { ConfigurationStore } from "@/stores/ConfigurationStore";

// TODO implement delete
const HEADERS = [
  { text: "Number", value: "number" },
  { text: "Response", value: "response" },
  //   { text: "", value: "actions" },
];

export default {
  name: "PlaceUnitList",
  computed: {
    selectedDeleteResponse: function () {
      return `${_.get(this.deleteResponse, "response")} (${_.get(
        this.deleteResponse,
        "abbreviation"
      )})`;
    },
  },
  components: {},
  data: function () {
    return {
      addDialog: false,
      addressToAdd: "",

      deleteDialog: false,
      deleteResponse: {},

      search: "",
      tableHeaders: HEADERS,

      subunits: [],
      subunitLoading: false,

      SurveyStore,
    };
  },
  methods: {
    addSubunit: function () {
      const toAdd = {
        address: this.addressToAdd,
      };
      this.clearAddDialog();
      this.subunitLoading = true;
      const parentId = _.get(SurveyStore, "selectedPlace.parentId");
      const placeId =
        parentId === undefined || parentId === null
          ? SurveyStore.selectedPlaceId
          : parentId;

      SurveyService.addSubunit(SurveyStore.survey.id, placeId, toAdd)
        .then((updatedSurvey) => {
          const parent = _.get(updatedSurvey, `places.${placeId}`);
          _.set(
            SurveyStore.survey,
            `places.${placeId}.subunits`,
            parent.subunits
          );

          _.forEach(parent.subunits, (subunitId) => {
            const subunit = _.get(updatedSurvey, `places.${subunitId}`);
            const existingPlace = _.get(
              SurveyStore.survey,
              `places.${subunitId}`
            );
            if (existingPlace === undefined) {
              const newPlace = PlaceDetail.from(subunit);

              newPlace.setPolygonMapCallback({
                add: (polygon) =>
                  MapDataStore.methods.addLayer("SurveyPage", polygon),
                remove: (polygon) =>
                  MapDataStore.methods.removeLayer("SurveyPage", polygon),
              });
              newPlace.setMarkerMapCallback({
                add: (marker) =>
                  MapDataStore.methods.addLayer(
                    "SurveyPage-Markers",
                    marker,
                    ConfigurationStore.map.textZoomLevel
                  ),
                remove: (marker) =>
                  MapDataStore.methods.removeLayer(
                    "SurveyPage-Markers",
                    marker
                  ),
              });

              _.set(SurveyStore.survey, `places.${subunitId}`, newPlace);
            }
          });

          this.subunits = this.getSubunits(SurveyStore.selectedPlaceId);
          this.subunitLoading = false;
          SurveyStore.surveyRev = SurveyStore.surveyRev + 1;
        })
        .catch((err) => {
          this.subunitLoading = false;
          console.error(err);
        });
    },
    clearAddDialog: function () {
      this.addDialog = false;
      this.addressToAdd = "";
    },

    deleteSubunit: function () {
      this.deleteDialog = false;
      OrganizationService.deleteSubunit(this.deleteResponse).then((org) => {
        OrgInfoStore.responseCodes = org.responseCodes;
      });
    },
    openDeleteDialog: function (item) {
      this.deleteResponse = item;
      this.deleteDialog = true;
    },

    getSubunits: function (placeId) {
      const parent = _.get(SurveyStore, `places.${placeId}.parentId`);
      let subunits = [];
      if (parent) {
        subunits = _(_.get(SurveyStore, `places.${parent}.subunits`))
          .filter((id) => id !== placeId)
          .value();
        subunits.push(parent);
      } else {
        subunits = _.get(SurveyStore, `places.${placeId}.subunits`);
      }

      subunits = _.map(subunits, (subunitId) => {
        return {
          number: this.parseSubunitNumber(subunitId),
          id: subunitId,
          detail: _.get(SurveyStore, `survey.places.${subunitId}`),
          response: _.get(
            SurveyStore,
            `survey.places.${subunitId}.responseCode.abbreviation`
          ),
        };
      });
      return _.sortBy(subunits, "number");
    },
    parseSubunitNumber: function (id) {
      const split = id.split("_");
      if (split.length === 1) {
        return _.get(
          SurveyStore,
          `survey.places.${id}.mapObject.address.number`
        );
      }
      return _.upperCase(id.split("_")[1]);
    },

    openSubunitPage: function (row) {
      SurveyStore.openPlacePage(row.id);
    },
  },
  mounted() {
    this.subunits = this.getSubunits(SurveyStore.selectedPlaceId);
  },
  props: {
    responseCodes: Array,
  },
  watch: {
    "SurveyStore.selectedPlaceId"(newId) {
      if (newId !== undefined) {
        this.subunits = this.getSubunits(newId);
      }
    },
  },
};
</script>

<style>
.place-subunit-list-table .v-data-footer {
  justify-content: right;
  padding: 10px;
}

.place-subunit-list-table .v-data-footer__select {
  display: none;
}

.place-subunit-add-button {
  margin-right: auto;
}
</style>
