<template>
  <div class="place-information-form">
    <v-progress-linear
      class="place-information-loading"
      :active="isPlaceDetailLoading"
      indeterminate
    ></v-progress-linear>
    <div class="place-information-content">
      <v-row>
        <v-col cols="12" md="6" class="place-detail-col">
          <v-text-field
            v-model="firstName"
            :disabled="isPlaceDetailLoading"
            label="First Name"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" class="place-detail-col">
          <v-text-field
            v-model="lastName"
            :disabled="isPlaceDetailLoading"
            label="Last Name"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="12" class="place-detail-col">
          <v-text-field
            v-model="phoneNumber"
            label="Phone Number"
            :disabled="isPlaceDetailLoading"
            :rules="[placeDetailRules.phoneNumber]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="12" class="place-detail-col">
          <v-textarea
            name="input-7-1"
            label="Notes"
            v-model="notes"
            auto-grow
            counter="500"
            :disabled="isPlaceDetailLoading"
          ></v-textarea>
        </v-col>
      </v-row>
      <div class="place-detail-action-buttons top-padding">
        <v-btn
          color="primary"
          @click="savePlaceMetadata()"
          :disabled="isPlaceDetailLoading || hasChanged === false"
        >
          Save
        </v-btn>
        <v-btn color="grey" @click="resetPlaceMetadata()"> Reset </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import { SurveyStore } from "@/stores/SurveyStore.js";

import OrganizationService from "@/services/OrganizationService";

class PlaceDetailMetadata {
  constructor(firstName, lastName, notes, phoneNumber) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.notes = notes;
    this.phoneNumber = phoneNumber;
  }
}

class PlaceDetail {
  id;
  placeId;
  organization;
  metadata;

  constructor(obj) {
    Object.assign(this, obj);
  }
}

export default {
  name: "PlaceInformationForm",
  computed: {
    hasChanged: function () {
      return (
        this.firstName !== this.initialState.firstName ||
        this.lastName !== this.initialState.lastName ||
        this.notes !== this.initialState.notes ||
        this.phoneNumber !== this.initialState.phoneNumber
      );
    },
  },
  components: {},
  data: function () {
    return {
      isPlaceDetailLoading: true,

      firstName: "",
      lastName: "",
      notes: "",
      phoneNumber: "",

      initialState: {},

      placeDetailRules: {
        phoneNumber: (value) => {
          if (value === undefined || value === "") {
            return true;
          }
          const pattern =
            /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
          return pattern.test(value) || "Invalid phone number.";
        },
      },

      SurveyStore,
    };
  },
  methods: {
    clearState: function () {
      this.firstName = "";
      this.lastName = "";
      this.notes = "";
      this.phoneNumber = "";
    },
    fetchPlaceMetadata: function (placeId) {
      if (placeId) {
        this.isPlaceDetailLoading = true;
        this.clearState();
        OrganizationService.getPlaceMetadata(placeId).then((response) => {
          this.isPlaceDetailLoading = false;
          const detail = new PlaceDetail(response);
          this.firstName = _.get(detail, "metadata.firstName");
          this.lastName = _.get(detail, "metadata.lastName");
          this.notes = _.get(detail, "metadata.notes");
          this.phoneNumber = _.get(detail, "metadata.phoneNumber");
          this.initialState = detail.metadata;
        });
      }
    },
    savePlaceMetadata: function () {
      const toSave = new PlaceDetailMetadata(
        this.firstName,
        this.lastName,
        this.notes,
        this.phoneNumber
      );
      this.isPlaceDetailLoading = true;

      // TODO error handling...
      OrganizationService.updatePlaceMetadata(
        SurveyStore.selectedPlaceId,
        toSave
      ).then(() => {
        this.isPlaceDetailLoading = false;
      });
    },
    resetPlaceMetadata: function () {
      this.firstName = this.initialState.firstName;
      this.lastName = this.initialState.lastName;
      this.notes = this.initialState.notes;
      this.phoneNumber = this.initialState.phoneNumber;
    },
  },
  mounted() {
    this.fetchPlaceMetadata(SurveyStore.selectedPlaceId);
  },
  props: {},
  watch: {
    "SurveyStore.selectedPlaceId"(newId) {
      if (newId !== undefined) {
        this.fetchPlaceMetadata(newId);
      }
    },
  },
};
</script>

<style>
.place-information-form .place-information-content {
  padding: 0 24px 16px;
}

.place-detail-action-buttons > button:nth-child(2) {
  float: right;
}

.place-information-loading {
  margin-bottom: 20px;
}

.place-detail-action-buttons.top-padding {
  padding-top: 24px;
}

.place-detail-col {
  padding: 0px 12px;
}
</style>
