<template>
  <v-navigation-drawer permanent class="navigation-rail" width="88px">
    <v-list class="nav-logo">
      <img src="@/assets/logo.svg" />
    </v-list>

    <v-list nav dense class="navigation-rail-items">
      <div
        link
        v-ripple
        v-for="nav in navItems"
        :class="getNavItemClass(nav.id)"
        :key="nav.id"
        @click="NavigationStore.toggleItem(nav.id)"
      >
        <div>
          <v-icon>{{ nav.icon }} </v-icon>
        </div>
        <div>{{ nav.title }}</div>
      </div>
    </v-list>

    <v-list nav dense>
      <div
        nav
        link
        v-ripple
        dense
        :class="getNavItemClass('items.settings', 'user-profile')"
        @click="NavigationStore.toggleItem('items.settings')"
      >
        <v-list-item-avatar color="primary" width="52px" height="52px">
          <span class="white--text">{{ getUserInitials() }}</span>
        </v-list-item-avatar>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import _ from "lodash";

import AuthUtil from "@/util/AuthUtil.js";

import { NavigationStore } from "@/stores/NavigationStore.js";

const NAV_ITEMS = [
  {
    icon: "mdi-clipboard-text-outline",
    id: "items.surveys",
    title: "Surveys",
  },
  {
    icon: "mdi-plus",
    id: "items.create",
    title: "New Survey",
  },
  {
    icon: "mdi-account-group-outline",
    id: "items.company",
    title: "Company",
  },
];

export default {
  name: "NavigationRail",
  components: {},
  computed: {
    navItems: function () {
      return _.filter(NAV_ITEMS, (nav) => NavigationStore.shouldShow(nav.id));
    },
  },
  methods: {
    getNavItemClass(key, additionalClass) {
      return NavigationStore.isSelected(key)
        ? `selected-nav nav-item ${additionalClass}`
        : `nav-item ${additionalClass}`;
    },
    getUserInitials() {
      const user = AuthUtil.getUser();
      if (user) {
        return user.firstName[0] + user.lastName[0];
      }
      return "";
    },
  },
  data() {
    return {
      NavigationStore,
      navClose: false,
    };
  },
};
</script>

<style scoped>
.navigation-rail {
  z-index: 100;
}

.navigation-rail .nav-logo {
  padding: 0px;
  height: 70px;
  width: 88px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation-rail .nav-logo > img {
  height: 50px;
}

.navigation-rail .nav-logo > div:first-child {
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 12px;
}

.navigation-rail .nav-logo > div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  font-family: "Biryani";
  font-size: 13px;
}

.navigation-rail .nav-item {
  height: 88px;
  cursor: pointer;
  user-select: none;
}

.navigation-rail .nav-item:hover {
  background: #cfcfcfbf;
}

.navigation-rail .nav-item.selected-nav:hover {
  background: #b5c8dd;
}

.nav-item > div:first-child {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 40px;
  margin-right: auto;
  margin-bottom: 4px;
  margin-left: auto;
  padding-top: 20px;
  border-radius: 16px;
  font-size: 12px;
  text-align: center;
}

.nav-item > div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  margin-top: 6px;
  text-align: center;
}

.navigation-rail .navigation-rail-items {
  padding: 0px;
}

.navigation-rail .navigation-rail-items .v-list-item {
  padding: 4px 20px;
  margin-bottom: 0px !important;
}

.navigation-rail .navigation-rail-items .v-list-item .v-list-item__icon {
  margin-right: 20px;
}

.navigation-rail .selected-nav {
  color: #1867c0 !important;
  background-color: #e3edf8;
}

.navigation-rail .selected-nav .v-icon {
  color: #1867c0 !important;
}

.navigation-rail .user-profile {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 88px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation-rail .user-profile > div:first-child {
  margin: 0px;
  padding-top: 0px;
  border-radius: 50%;
}

.navigation-rail .user-profile span {
  width: 100%;
  font-size: 1.2em;
}
</style>
