<template>
  <div :class="getPageClass" :style="getPageStyle">
    <slot name="content"></slot>
  </div>
</template>

<script>
import { LayoutStore } from "@/stores/LayoutStore";
import { NavigationStore } from "@/stores/NavigationStore";

export default {
  name: "PageContainer",
  computed: {
    getPageClass: function () {
      return `page-container ${this.isOpen ? "open" : ""} ${
        this.isCollapsed ? "page-collapsed" : ""
      }`;
    },
    getPageStyle: function () {
      if (
        LayoutStore.isMobile() === true &&
        NavigationStore.mobileMapView === false
      ) {
        return { height: "calc(100% - 80px)" };
      }
      return { height: "100%" };
    },
  },
  components: {},
  methods: {},
  data() {
    return {};
  },
  props: {
    isCollapsed: Boolean,
    isOpen: Boolean,
  },
};
</script>

<style scoped>
.page-container {
  display: inline-block;
  position: absolute;
  z-index: 90;
  width: 480px;
  background: #ffffff;
  transition: all 0.4s ease;
  left: -480px;
  top: 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .page-container {
    display: inline-block;
    position: absolute;
    z-index: 90;
    width: 100%;
    background: #ffffff;
    transition: all 0.4s ease;
    left: -100%;
    top: 0px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    overflow: hidden;
  }
}

.page-container.open {
  left: 0px;
}

.page-container.page-collapsed {
  transform: translateY(calc(100% - 70px));
}
</style>
