import Vue from "vue";
import { reactive } from "vue";
import { EventMessageUtils, EventType, InteractionStatus } from "@azure/msal-browser";
import AuthUtil from "@/util/AuthUtil";

/**
 * Helper function to determine whether 2 arrays are equal
 * Used to avoid unnecessary state updates
 * @param arrayA 
 * @param arrayB 
 */
function accountArraysAreEqual(arrayA, arrayB) {
    if (arrayA.length !== arrayB.length) {
        return false;
    }

    const comparisonArray = [...arrayB];

    return arrayA.every((elementA) => {
        const elementB = comparisonArray.shift();
        if (!elementA || !elementB) {
            return false;
        }

        return (elementA.homeAccountId === elementB.homeAccountId) &&
            (elementA.localAccountId === elementB.localAccountId) &&
            (elementA.username === elementB.username);
    });
}

export const msalPlugin = {
    install: (app, msalInstance) => {
        const inProgress = InteractionStatus.Startup;
        const accounts = msalInstance.getAllAccounts();

        const state = reactive({
            instance: msalInstance,
            inProgress: inProgress,
            accounts: accounts
        });

        Vue.prototype.$msal = state;

        msalInstance.addEventCallback((message) => {
            switch (message.eventType) {
                case EventType.ACCOUNT_ADDED:
                case EventType.ACCOUNT_REMOVED:
                case EventType.LOGIN_SUCCESS:
                case EventType.SSO_SILENT_SUCCESS:
                case EventType.HANDLE_REDIRECT_END:
                case EventType.SSO_SILENT_FAILURE:
                case EventType.LOGOUT_END:
                case EventType.ACQUIRE_TOKEN_SUCCESS:
                    {
                        const currentAccounts = msalInstance.getAllAccounts();
                        if (!accountArraysAreEqual(currentAccounts, state.accounts)) {
                            state.accounts = currentAccounts;
                        }
                        break;
                    }
                case EventType.ACQUIRE_TOKEN_FAILURE:
                case EventType.LOGIN_FAILURE:
                    {
                        // TODO fix this... redirect to error page instead, figure out root cause
                        sessionStorage.clear();
                        localStorage.clear();
                        AuthUtil.login();
                        break;
                    }
            }

            const status = EventMessageUtils.getInteractionStatusFromEvent(message, state.inProgress);
            if (status !== null) {
                state.inProgress = status;
            }
        });
    }
}