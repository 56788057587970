import OrganizationService from '@/services/OrganizationService';
import { reactive } from 'vue'

export const OrgInfoStore = reactive({
    id: "",
    name: "",
    responseCodes: [],
    responseCodeColors: {},

    isInitialized: false,

    fetch: () => {
        OrganizationService.getOrganization().then((org) => {
            OrgInfoStore.id = org.id;
            OrgInfoStore.name = org.name;
            OrgInfoStore.responseCodes = org.responseCodes;
            OrgInfoStore.responseCodeColors = org.responseCodeColors;
            OrgInfoStore.isInitialized = true;
        });
    }
});