<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="surveyors"
      item-key="userId"
      class="surveyor-table"
      :mobile-breakpoint="0"
      :loading="loading"
      loading-text="Loading surveyors..."
    >
      <template #[`item.name`]="{ item }">
        <span>{{ `${item.firstName} ${item.lastName}` }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  name: "SurveySurveyors",
  computed: {},
  components: {},
  data: function () {
    return {
      headers: [
        {
          sortable: false,
          text: "Name",
          value: "name",
        },
        {
          sortable: false,
          text: "Email",
          value: "email",
        },
      ],
      loading: false,
      surveyors: [],
    };
  },
  methods: {
    fetchSurveyors: function (ids) {
      this.loading = true;
      UserService.getSurveyorsInSurvey(ids).then((response) => {
        this.surveyors = Object.values(response);
        this.loading = false;
      });
    },
  },
  mounted() {
    this.fetchSurveyors(this.surveyorIds);
  },
  props: {
    surveyorIds: Array,
  },
  watch: {
    surveyorIds: function (ids) {
      this.fetchSurveyors(ids);
    },
  },
};
</script>

<style>
.surveyor-table .v-data-footer__select {
  display: none;
}

.surveyor-table .v-data-footer {
  justify-content: right;
  padding: 8px;
}
</style>
