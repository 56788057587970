<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Edit Place</span>
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="label"
        hint="Enter a new label for this place"
        label="Label"
      ></v-text-field>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        class="place-edit-delete"
        text
        @click="deletePlace()"
      >
        <v-icon> mdi-delete </v-icon>Delete
      </v-btn>
      <v-btn color="primary" text @click="saveEdit()" :disabled="changesMade">
        Save
      </v-btn>
      <v-btn color="blue darken-1" text @click="cancelEdit()"> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";

const COMPONENT_NAME = "PlaceEditTool";

export default {
  name: COMPONENT_NAME,
  components: {},
  computed: {
    changesMade: function () {
      return this.label === _.get(this.place, "address.number");
    },
  },
  data: function () {
    return {
      label: "",
    };
  },
  methods: {
    cancelEdit: function () {
      if (this.cancelCallback) {
        this.cancelCallback();
      }
    },
    deletePlace: function () {
      if (this.deleteCallback) {
        this.deleteCallback();
      }
    },
    saveEdit: function () {
      if (this.saveCallback) {
        this.saveCallback({
          label: this.label,
        });
      }
    },
  },
  mounted() {
    this.label = _.get(this.place, "address.number");
  },
  props: {
    cancelCallback: Function,
    deleteCallback: Function,
    place: Object,
    saveCallback: Function,
  },
  watch: {
    place: function (updatedPlace) {
      this.label = _.get(updatedPlace, "address.number");
    },
  },
};
</script>

<style scoped>
.place-edit-delete {
  position: absolute;
  left: 8px;
}
</style>
