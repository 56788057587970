<template>
  <div class="survey-page" v-if="selectedSurvey !== undefined">
    <div
      class="survey-page-map-view-button"
      v-if="LayoutStore.isMobile()"
      v-ripple
      @click="openMapView"
    >
      <v-icon left> mdi-map-outline </v-icon>
      <span>Open Map View</span>
      <v-icon left> mdi-open-in-new </v-icon>
    </div>
    <v-expansion-panels v-model="selectedPanel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header class="survey-detail-expansion-header"
          ><v-icon left> mdi-text-box </v-icon>
          <h3>Overview</h3></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <SurveyOverview :survey="selectedSurvey" />
          <div
            class="survey-detail-actions"
            v-if="UserInfoStore.admin === true"
          >
            <v-btn @click="finishDialog = true" color="success">
              <v-icon left> mdi-check </v-icon>
              <span>Finish</span>
            </v-btn>
            <v-btn @click="deleteDialog = true" color="error">
              <v-icon left> mdi-delete </v-icon>
              <span>Delete</span>
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="survey-detail-expansion-header"
          ><v-icon left> mdi-map-marker </v-icon>
          <h3>Places</h3></v-expansion-panel-header
        >
        <v-expansion-panel-content class="survey-detail-content no-padding">
          <SurveyPlaceDetail
            :openPlacePage="SurveyStore.openPlacePage"
            :survey="selectedSurvey"
            :surveyRev="SurveyStore.surveyRev"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="UserInfoStore.admin">
        <v-expansion-panel-header class="survey-detail-expansion-header"
          ><v-icon left> mdi-account-group </v-icon>
          <h3>Surveyors</h3></v-expansion-panel-header
        >
        <v-expansion-panel-content class="survey-detail-content no-padding">
          <SurveySurveyors :surveyorIds="surveyors" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="UserInfoStore.admin">
        <v-expansion-panel-header class="survey-detail-expansion-header"
          ><v-icon left> mdi-export-variant </v-icon>
          <h3>Export</h3></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <SurveyExport :survey="selectedSurvey" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog v-model="finishDialog" max-width="280px">
      <v-card>
        <v-card-title class="text-h5">Finish Survey</v-card-title>
        <v-card-subtitle class="dialog-subheader">{{
          getFinishDeleteSubheader()
        }}</v-card-subtitle>
        <v-card-text>
          <b
            >This will finish the survey and remove it from the Surveys list. Do
            you want to continue?</b
          >
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="finishSurvey()">
            Yes
          </v-btn>
          <v-btn color="blue darken-1" text @click="finishDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="280px">
      <v-card>
        <v-card-title class="text-h5">Delete Survey</v-card-title>
        <v-card-subtitle class="dialog-subheader">{{
          getFinishDeleteSubheader()
        }}</v-card-subtitle>
        <v-card-text>
          <b
            >This will delete the survey from the system. Do you want to
            continue?</b
          >
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="deleteSurvey()"> Yes </v-btn>
          <v-btn color="blue darken-1" text @click="deleteDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";

import SurveyExport from "@/components/pages/survey/SurveyExport.vue";
import SurveyOverview from "@/components/pages/survey/SurveyOverview.vue";
import SurveyPlaceDetail from "@/components/pages/survey/SurveyPlaceDetail.vue";
import SurveySurveyors from "@/components/pages/survey/SurveySurveyors.vue";

import PlaceDetail from "@/model/place/PlaceDetail.js";

import { ConfigurationStore } from "@/stores/ConfigurationStore.js";
import { LayoutStore } from "@/stores/LayoutStore.js";
import { MapDataStore } from "@/stores/MapDataStore.js";
import { NavigationStore } from "@/stores/NavigationStore";
import { OrgInfoStore } from "@/stores/OrgInfoStore";
import { SurveyStore } from "@/stores/SurveyStore.js";
import { UserInfoStore } from "@/stores/UserInfoStore.js";

import SurveyService from "@/services/SurveyService";

const COMPONENT_NAME = "SurveyPage";
const COMPONENT_MARKER_LAYER = `${COMPONENT_NAME}-Markers`;

export default {
  name: COMPONENT_NAME,
  computed: {},
  components: {
    SurveyExport,
    SurveyOverview,
    SurveyPlaceDetail,
    SurveySurveyors,
  },
  data: function () {
    return {
      selectedPanel: [0, 1],
      selectedSurvey: undefined,
      surveyors: [],

      finishDialog: false,
      deleteDialog: false,
      placeDialog: false,

      LayoutStore,
      OrgInfoStore,
      SurveyStore,
      UserInfoStore,
    };
  },
  methods: {
    deleteSurvey: function () {
      SurveyService.deleteSurvey(this.selectedSurvey.id)
        .then(() => {
          this.deleteDialog = false;
          this.resetState();
          this.backButtonClick();
          // TODO some success notification and go back to main list
        })
        .catch((e) => {
          // TODO some user feedback???
          console.error(e);
        });
    },
    finishSurvey: function () {
      SurveyService.finishSurvey(this.selectedSurvey.id)
        .then(() => {
          this.finishDialog = false;
          this.resetState();
          this.backButtonClick();
          // TODO some success notification and go back to main list
        })
        .catch((e) => {
          // TODO some user feedback???
          console.error(e);
        });
    },
    getFinishDeleteSubheader: function () {
      return _.get(this.selectedSurvey, "name");
    },

    openMapView: function () {
      NavigationStore.mobileMapView = true;
      setTimeout(() => {
        MapDataStore.methods.refreshMap();
        MapDataStore.methods.zoomToLayerGroup(COMPONENT_NAME);
      }, 700);
    },
    resetState: function () {
      MapDataStore.methods.clearLayer(COMPONENT_NAME);
      MapDataStore.methods.clearLayer(COMPONENT_MARKER_LAYER);
      this.selectedPanel = [0];
    },
  },
  mounted() {
    this.surveyors = _.get(this.survey, "surveyors", []);
  },
  props: {
    backButtonClick: Function,
  },
  watch: {
    "SurveyStore.survey"(newSurvey, oldSurvey) {
      if (newSurvey) {
        this.selectedSurvey = newSurvey;
        this.surveyors = _.get(newSurvey, "surveyors", []);

        const placeDetailObjects = _.map(newSurvey.places, (place) => {
          const obj = PlaceDetail.from(place, {
            onClick: (place) => {
              SurveyStore.openPlacePage(place.id);
            },
          });
          _.set(SurveyStore.survey, `places.${obj.getId()}`, obj);
          return obj;
        });

        _.forEach(placeDetailObjects, (placeDetail) => {
          if (placeDetail === undefined) {
            return;
          }

          placeDetail.setPolygonMapCallback({
            add: (polygon) =>
              MapDataStore.methods.addLayer(COMPONENT_NAME, polygon),
            remove: (polygon) =>
              MapDataStore.methods.removeLayer(COMPONENT_NAME, polygon),
          });
          placeDetail.setMarkerMapCallback({
            add: (marker) =>
              MapDataStore.methods.addLayer(
                COMPONENT_MARKER_LAYER,
                marker,
                ConfigurationStore.map.textZoomLevel
              ),
            remove: (marker) =>
              MapDataStore.methods.removeLayer(COMPONENT_MARKER_LAYER, marker),
          });
        });
        MapDataStore.methods.zoomToLayerGroup(COMPONENT_NAME);
      }
      if (oldSurvey) {
        this.resetState();
      }
    },
  },
};
</script>

<style>
.survey-page {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 30px;
}

.survey-page .v-expansion-panel {
  border-radius: 0px !important;
}

.survey-page .v-expansion-panel::before {
  box-shadow: 0px 1px 0px 0px rgb(0 0 0 / 14%),
    0px -1px 0px 0px rgb(0 0 0 / 14%);
}

.survey-page-map-view-button {
  padding: 14px 23px;
}

.survey-page-map-view-button > span {
  text-transform: capitalize;
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
  transition: none;
}

.survey-page-map-view-button > i:nth-child(3) {
  float: right;
  font-size: 20px;
  padding-top: 2px;
}

.survey-detail-expansion-header > i {
  justify-content: left !important;
  flex: none !important;
}

.survey-detail-expansion-header > h3 {
  text-transform: capitalize;
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
  transition: none;
}

.survey-detail-content.no-padding > div:first-child {
  padding: 0px;
}

.survey-detail-actions {
  padding: 4px 12px;
}

.survey-detail-actions button:nth-child(2) {
  float: right;
}

.dialog-subheader {
  padding: 0 24px 12px !important;
}

.survey-place-detail-card h3 {
  padding-top: 12px;
  padding-bottom: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.place-detail-responses {
  height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
