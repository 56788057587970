<template>
  <!-- <div class="page-content"> -->
  <div class="page-content" :style="style">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PageContent",
  components: {},
  computed: {
    style: function () {
      return { height: `calc(100% - ${70 + this.footerHeight}px)` };
    },
  },
  methods: {},
  data() {
    return {};
  },
  props: {
    footerHeight: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.page-content {
  overflow-y: auto;
  height: 100%;
}
</style>
