import { ConfigurationStore } from "../stores/ConfigurationStore.js";
import ApiService from "./ApiService.js";

export default class ReportService {

    static async getSupportedFormatsByType(type) {
        const response = await ApiService.makeApiCall(`${ConfigurationStore.backend}/api/report/${type}/format`, { method: 'GET' });
        return await response.json();
    }

    static getReportDownloadLink(type, format, id) {
        return `${ConfigurationStore.backend}/api/report/${type}/${format}/${id}`;
    }

    static REPORT_TYPES = {
        SURVEY: 'survey'
    };
}