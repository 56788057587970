<template>
  <v-data-table
    :headers="headers"
    :items="attempts"
    item-key="timestamp"
    :search="search"
    class="attempts-table"
    :sort-by="['timestamp']"
    :sort-desc="true"
    :mobile-breakpoint="0"
    no-data-text="No attempts have been made"
  >
    <template v-slot:top>
      <v-text-field
        v-model="search"
        label="Search"
        class="mx-4"
        append-icon="mdi-magnify"
      ></v-text-field>
    </template>
    <template #[`item.timestamp`]="{ item }">
      <span>{{ new Date(item.timestamp).toLocaleString() }}</span>
    </template>
  </v-data-table>
</template>

<script>
const COMPONENT_NAME = "PlaceAttemptDetail";

export default {
  name: COMPONENT_NAME,
  computed: {},
  components: {},
  data: function () {
    return {
      attemptsByPlace: [],
      headers: [
        { text: "Date", value: "timestamp" },
        { text: "Response", value: "response.abbreviation" },
      ],
      search: "",
    };
  },
  methods: {},
  mounted() {},
  props: {
    attempts: Array,
  },
  watch: {},
};
</script>

<style>
.attempts-table .v-data-footer__select {
  display: none;
}

.attempts-table .v-data-footer {
  justify-content: right;
  padding: 8px;
}
</style>
