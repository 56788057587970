<template>
  <div class="survey-creation-page">
    <PageHeader header="New Survey" icon="mdi-plus" />
    <PageContent>
      <div
        :class="
          submitSuccess === undefined && submitPending === false
            ? 'survey-creation'
            : 'survey-creation done'
        "
      >
        <v-stepper
          v-model="currentStep"
          vertical
          class="survey-creation-stepper"
        >
          <!-- TODO validate the survey name length is greater than 0 -->
          <v-stepper-step :complete="currentStep > 1" step="1" :editable="true">
            Survey Name
          </v-stepper-step>
          <v-stepper-content step="1" :editable="true">
            <v-text-field
              label="Name"
              counter
              maxlength="50"
              v-model="surveyName"
            ></v-text-field>
            <v-btn
              color="primary"
              @click="currentStep = 2"
              :disabled="surveyName.length == 0"
            >
              Continue
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="currentStep > 2"
            step="2"
            :editable="currentStep > 2"
          >
            Select the Area
          </v-stepper-step>
          <v-stepper-content step="2" class="survey-creation-place-select">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  small
                  @click="mapSearchDialog = true"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left> mdi-magnify </v-icon>
                  <span>Search</span>
                </v-btn>
              </template>
              <span>Search the map by an address</span>
            </v-tooltip>

            <PlaceSelectTool :step="currentStep" />
            <v-btn color="primary" @click="currentStep = 3"> Continue </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="currentStep > 3"
            step="3"
            :editable="currentStep > 3"
          >
            Add Surveyors
            <small>Optional</small>
          </v-stepper-step>
          <v-stepper-content step="3">
            <SurveyorSelectTool />
            <v-btn color="primary" @click="currentStep = 4"> Continue </v-btn>
          </v-stepper-content>

          <v-stepper-step step="4"> Review </v-stepper-step>
          <v-stepper-content step="4" class="survey-creation-review">
            <v-list>
              <v-list-item @click="currentStep = 1">
                <v-list-item-content>
                  <v-list-item-title>Name</v-list-item-title>
                  <v-list-item-subtitle>{{ surveyName }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <v-list-item @click="currentStep = 2">
                <v-list-item-content>
                  <v-list-item-title>Area</v-list-item-title>
                  <v-list-item-subtitle>{{
                    getNumberOfPlaces
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <v-list-item @click="currentStep = 3">
                <v-list-item-content>
                  <v-list-item-title>Surveyors</v-list-item-title>
                  <v-list-item-subtitle>{{
                    getNumberOfSurveyors
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-btn color="primary" @click="createSurvey"> Submit </v-btn>
            <v-btn text @click="createAnother"> Cancel </v-btn>
          </v-stepper-content>
        </v-stepper>
      </div>
      <div
        :class="
          submitSuccess === true && submitPending === false
            ? 'survey-creation-success'
            : 'survey-creation-success hide'
        "
      >
        <div class="success-message">
          <v-icon color="success lighten-1">mdi-check-circle-outline </v-icon>
          <div>Survey created successfully!</div>
        </div>
        <div>
          <v-btn color="primary" class="action-button" @click="createAnother()">
            <v-icon>mdi-plus</v-icon>
            Create Another
          </v-btn>
        </div>
      </div>
      <div v-if="submitPending === true" class="survey-creation-pending">
        <v-progress-linear indeterminate></v-progress-linear>
        <div class="survey-creation-pending-message">
          <div>Creating survey...</div>
        </div>
      </div>
    </PageContent>
    <v-dialog v-model="mapSearchDialog" max-width="300px">
      <MapSearchTool :goToAddressClick="() => (mapSearchDialog = false)" />
    </v-dialog>
  </div>
</template>

<script>
import PageContent from "@/components/pages/PageContent.vue";
import PageHeader from "@/components/pages/PageHeader.vue";

import MapSearchTool from "@/components/tools/MapSearchTool";
import PlaceSelectTool from "@/components/tools/PlaceSelectTool";
import SurveyorSelectTool from "@/components/tools/SurveyorSelectTool";

import { MapDataStore } from "@/stores/MapDataStore.js";
import { NavigationStore } from "@/stores/NavigationStore.js";
import { SurveyCreationStore } from "@/stores/SurveyCreationStore.js";

import SurveyService from "@/services/SurveyService";

const COMPONENT_NAME = "NewSurveyPage";

export default {
  name: COMPONENT_NAME,
  components: {
    MapSearchTool,
    PageContent,
    PageHeader,
    PlaceSelectTool,
    SurveyorSelectTool,
  },
  computed: {
    getNumberOfPlaces: function () {
      return `${Object.keys(SurveyCreationStore.places).length} places`;
    },
    getNumberOfSurveyors: function () {
      return `${Object.keys(SurveyCreationStore.surveyors).length} surveyors`;
    },
  },
  data() {
    return {
      currentStep: 1,
      mapSearchDialog: false,
      NavigationStore,
      submitPending: false,
      submitSuccess: undefined,
      surveyName: "",
    };
  },
  methods: {
    createAnother: function () {
      this.clearSurvey();
      this.submitSuccess = undefined;
      this.submitPending = false;
    },
    createSurvey: function () {
      // TODO move name to store elsewhere (... preferably move SurveyName step to a component)
      SurveyCreationStore.name = this.surveyName;
      this.submitPending = true;

      SurveyService.createSurvey({
        name: SurveyCreationStore.name,
        places: SurveyCreationStore.places,
        surveyors: Object.keys(SurveyCreationStore.surveyors),
      })
        .then(() => {
          // TODO notification that survey complete was successful + option to create a new survey
          MapDataStore.methods.clearMap();
          this.submitSuccess = true;
          this.submitPending = false;
        })
        .catch((e) => {
          // TODO show error message in UI (maybe just retry button?)
          console.error(e);
          this.submitSuccess = false;
          this.submitPending = false;
        });
    },
    clearSurvey: function () {
      this.currentStep = 1;
      this.surveyName = "";
      SurveyCreationStore.places = {};
      SurveyCreationStore.placesInternal = {};
      SurveyCreationStore.surveyors = {};
    },
  },
  mounted() {
    // register callback
    NavigationStore.registerChangeListener(COMPONENT_NAME, () =>
      this.clearSurvey()
    );
  },
  props: {},
};
</script>

<style scoped>
.survey-creation .survey-creation-stepper {
  box-shadow: none;
}

.survey-creation-page {
  height: 100%;
}

.survey-creation {
  opacity: 1;
  transition: all 0.4s ease;
  width: 100%;
}

.survey-creation.done {
  opacity: 0;
  z-index: -1;
}

.survey-creation-success {
  position: absolute;
  transition: all 0.4s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 71px;
  width: 100%;
  text-align: center;
}

.survey-creation-success.hide {
  opacity: 0;
  z-index: -1;
}

.success-message > i {
  font-size: 120px;
}

.success-message > div {
  font-size: 1.2em;
  padding-top: 8px;
}

.survey-creation-success button {
  margin-top: 28px;
}

.survey-creation-place-select {
  padding-top: 8px !important;
}

.survey-creation-place-select > div > button:first-child {
  margin-bottom: 8px;
}

.survey-creation-pending {
  top: 70px;
  position: absolute;
  width: 100%;
}

.survey-creation-pending-message {
  display: flex;
  justify-content: center;
  padding: 12px;
}
</style>
