import UserService from '@/services/UserService';
import { reactive } from 'vue'

export const UserInfoStore = reactive({
    admin: false,
    email: "",
    firstName: "",
    id: "",
    lastName: "",
    name: "",
    organization: "",

    isInitialized: false,

    fetch: () => {
        UserService.getMyUserInfo().then((response) => {
            UserInfoStore.admin = response.admin;
            UserInfoStore.email = response.email;
            UserInfoStore.firstName = response.firstName;
            UserInfoStore.id = response.id;
            UserInfoStore.lastName = response.lastName;
            UserInfoStore.name = `${response.firstName} ${response.lastName}`;
            UserInfoStore.organization = response.organization;
            UserInfoStore.isInitialized = true;
        });
    }
});