<template>
  <div
    :class="
      placeToPlaceTransition === true
        ? 'survey-detail place-detail-fade-animate'
        : 'survey-detail'
    "
  >
    <PageHeader
      :header="header"
      icon="mdi-arrow-left"
      :iconClick="SurveyStore.backButton"
    />
    <PageContent>
      <div
        class="place-detail-page"
        v-if="SurveyStore.selectedPlaceId !== undefined"
      >
        <v-expansion-panels v-model="selectedPanel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="place-detail-expansion-header"
              ><v-icon left> mdi-code-tags-check </v-icon>
              <h3>Response</h3></v-expansion-panel-header
            >
            <PlaceResponseForm />
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="place-detail-expansion-header"
              ><v-icon left> mdi-card-account-details-outline </v-icon>
              <h3>Information</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content class="place-detail-content no-padding">
              <PlaceInformationForm />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="place-detail-expansion-header"
              ><v-icon left> mdi-signature-text </v-icon>
              <h3>Map Label</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content class="place-detail-content no-padding">
              <PlaceLabelEditForm />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="place-detail-expansion-header"
              ><v-icon left> mdi-office-building-outline </v-icon>
              <h3>Other Addresses</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content class="place-detail-content no-padding">
              <PlaceUnitList />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="UserInfoStore.admin">
            <v-expansion-panel-header class="place-detail-expansion-header"
              ><v-icon left> mdi-arrow-u-right-top </v-icon>
              <h3>Attempts</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content class="place-detail-content no-padding">
              <PlaceAttemptDetail :attempts="SurveyStore.placeAttempts" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </PageContent>
  </div>
</template>

<script>
import _ from "lodash";

import PageContent from "@/components/pages/PageContent.vue";
import PageHeader from "@/components/pages/PageHeader.vue";
import PlaceAttemptDetail from "@/components/pages/place/PlaceAttemptDetail.vue";
import PlaceInformationForm from "@/components/pages/place/PlaceInformationForm.vue";
import PlaceLabelEditForm from "@/components/pages/place/PlaceLabelEditForm.vue";
import PlaceResponseForm from "@/components/pages/place/PlaceResponseForm.vue";
import PlaceUnitList from "@/components/pages/place/PlaceUnitList.vue";

import { SurveyStore } from "@/stores/SurveyStore.js";
import { UserInfoStore } from "@/stores/UserInfoStore.js";

export default {
  name: "PlaceDetailPage",
  computed: {
    header: function () {
      return _.get(SurveyStore.selectedPlace, "mapObject.address.addressLine1");
    },
  },
  components: {
    PageContent,
    PageHeader,
    PlaceAttemptDetail,
    PlaceInformationForm,
    PlaceLabelEditForm,
    PlaceResponseForm,
    PlaceUnitList,
  },
  data: function () {
    return {
      placeToPlaceTransition: false,

      selectedPanel: [0],
      selectedSurvey: undefined,

      SurveyStore,
      UserInfoStore,
    };
  },
  methods: {},
  mounted() {},
  props: {},
  watch: {
    "SurveyStore.selectedPlaceId"(newId, oldId) {
      if (newId !== undefined && oldId !== undefined && newId !== oldId) {
        this.placeToPlaceTransition = true;
        setTimeout(() => (this.placeToPlaceTransition = false), 500);
      }

      if (newId !== undefined) {
        SurveyStore.placeAttempts = _.get(
          SurveyStore.survey,
          `places.${newId}.attempts`
        );
      }
    },
  },
};
</script>

<style>
.place-detail-page {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 30px;
}

.place-detail-page .v-expansion-panel {
  border-radius: 0px !important;
}

.place-detail-page .v-expansion-panel::before {
  box-shadow: 0px 1px 0px 0px rgb(0 0 0 / 14%),
    0px -1px 0px 0px rgb(0 0 0 / 14%);
}

.place-detail-expansion-header > i {
  justify-content: left !important;
  flex: none !important;
}

.place-detail-expansion-header > h3 {
  text-transform: capitalize;
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
  transition: none;
}

.place-detail-content.no-padding > div:first-child {
  padding: 0px;
}

.place-detail-action-buttons > button:nth-child(2) {
  float: right;
}

.place-detail-action-buttons.top-padding {
  padding-top: 24px;
}

.place-detail-col {
  padding: 0px 12px;
}

.place-detail-fade-animate {
  -webkit-animation-name: place-detail-fade;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  animation-name: place-detail-fade;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
}

@-webkit-keyframes place-detail-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes place-detail-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
