import L from "leaflet";

import _ from "lodash";

export default class LeafletUtil {

    static defaultFill = "#FFFFFF";

    static latitudeLongitudeToJson(latlon) {
        return latlon[0].map((element) => {
            return {
                lat: element.lat,
                lon: element.lng
            }
        });
    }

    static pointToLatLonJson(latlon) {
        return {
            lat: latlon.lat,
            lon: latlon.lng
        };
    }

    static boundsToPolygon(bounds) {
        return this.latitudeLongitudeToJson([[bounds.getNorthWest(), bounds.getNorthEast(), bounds.getSouthEast(), bounds.getSouthWest()]]);
    }

    static getMapMarker(latLon) {
        return L.marker(new L.LatLng(latLon.lat, latLon.lon));
    }

    static mapObjectToPolygonAndMarker(obj, options = {}) {
        const outline = obj.bounds.map(bound => [bound.lat, bound.lon]);
        const polygon = L.polygon(outline, { color: "#757575", fillColor: options.fillFunc ? options.fillFunc(obj) : this.defaultFill, fill: true, fillOpacity: 1, weight: 1 });
        const location = polygon.getBounds().getCenter().lat + "_" + polygon.getBounds().getCenter().lng;

        const marker = L.marker(polygon.getBounds().getCenter(), {
            icon: L.divIcon({
                className: "parcel-address",
                html: options.addressFormatter ? options.addressFormatter(obj) : obj.address.number,
            }),
            zIndexOffset: 1000,
        });
        marker.updateText = (textUpdate) => {
            // console.trace();
            marker.setIcon(L.divIcon({
                className: "parcel-address",
                html: textUpdate,
            }))
        };

        if (options.onClick) {
            polygon.on("click", () => options.onClick(obj));
            marker.on("click", () => options.onClick(obj));
        }
        if (options.onMouseOver) {
            polygon.on("mouseover", () => options.onMouseOver(obj));
            marker.on("mouseover", () => options.onMouseOver(obj));
        }
        if (options.onMouseOut) {
            polygon.on("mouseout", () => options.onMouseOut(obj));
            marker.on("mouseout", () => options.onMouseOut(obj));
        }

        obj.polygon = polygon;
        obj.marker = marker;
        obj.location = location;
        return obj;
    }

    static mapObjectsToPolygonAndMarker(objects, options = {}) {
        const duplicates = {};
        return _(objects).filter(obj => {
            // check for duplicates, and skip if parcel at this location has already been found
            const location = obj.center.lat + "_" + obj.center.lon;
            if (duplicates[location]) {
                console.warn(`Skipping parcel with ID: ${obj.id}, object at location: [${obj.center.lat}, ${obj.center.lon}] already found`);
                return false;
            }
            duplicates[location] = true;
            return true;
        })
            .map(obj => this.mapObjectToPolygonAndMarker(obj, options))
            .value();
    }

}