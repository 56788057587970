import { reactive } from 'vue'

export const ConfigurationStore = reactive({
    auth: {
        authority: 'https://fieldgather.b2clogin.com/fieldgather.onmicrosoft.com/B2C_1_SignUpAndSignIn',
        clientId: 'bb8682fe-d0dd-41a0-9e34-b04d641d15c1',
        knownAuthorities: ["fieldgather.b2clogin.com"],
        // redirectUri: 'http://localhost:8080',
        redirectUri: 'https://fieldgather.com',
        scopes: ['https://fieldgather.onmicrosoft.com/api/surveys/survey.get']
    },
    backend: "https://fieldgather.com",
    // backend: "http://localhost:8081",
    map: {
        maxZoom: 22,
        minZoom: 3,
        textZoomLevel: 18,
        baseTileServer: "https://fieldgather.com/api/tile/png/base/{z}/{x}/{y}",
        roadsTileServer: "https://fieldgather.com/api/tile/png/roads/{z}/{x}/{y}"
    }
})