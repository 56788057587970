import Vue from "vue";
import _ from "lodash";

import { loginRequest } from "@/authConfig";
import { EventType, InteractionStatus } from "@azure/msal-browser";

export default class AuthUtil {

    static token = undefined;

    static isAuthenticated() {
        const msal = Vue.prototype.$msal;
        return msal.accounts.length > 0;
    }

    static login() {
        const msal = Vue.prototype.$msal;
        const accounts = msal.instance.getAllAccounts();
        if (accounts.length > 0) {
            msal.instance.setActiveAccount(accounts[0]);
        }

        msal.instance.addEventCallback((event) => {
            // set active account after redirect
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                const account = event.payload.account;
                msal.instance.setActiveAccount(account);
            }
        }, error => {
            console.log('error', error);
        });

        console.log('get active account', msal.instance.getActiveAccount());

        // handle auth redired/do all initial setup for msal
        msal.instance.handleRedirectPromise().then(() => {
            // Check if user signed in 
            const account = msal.instance.getActiveAccount();
            if (!account) {
                // redirect anonymous user to login page 
                msal.instance.loginRedirect(loginRequest);
            }
        }).catch(err => {
            // TODO: Handle errors
            console.log(err);
        });
    }

    static logout() {
        const msal = Vue.prototype.$msal;
        msal.instance.logoutRedirect();
    }

    static async getToken(fetchNew = false) {
        if (AuthUtil.token !== undefined && fetchNew === false) {
            return AuthUtil.token;
        }

        const msal = Vue.prototype.$msal;
        const inProgress = msal.inProgress;
        if (inProgress.value === InteractionStatus.Startup || inProgress.value === InteractionStatus.HandleRedirect) {
            try {
                const response = await msal.instance.handleRedirectPromise()
                if (response) {
                    AuthUtil.token = response;
                    // return;
                }
            } catch (e) {
                console.error(e);
                AuthUtil.token = undefined;
                // return;
            }
        }

        if (AuthUtil.token !== undefined) {
            return AuthUtil.token;
        }

        try {
            const response = await msal.instance.acquireTokenSilent(loginRequest);
            AuthUtil.token = response;
        } catch (e) {
            console.error(e);
            if (inProgress.value !== InteractionStatus.None) {
                // return;
            }

            await msal.instance.loginRedirect(loginRequest).catch((e) => {
                console.error(e);
                AuthUtil.token = undefined;
            });
        }
        return AuthUtil.token;
    }

    static getAccount() {
        const msal = Vue.prototype.$msal;
        return msal.accounts[0];
    }

    static getUser() {
        const msal = Vue.prototype.$msal;
        const idToken = _.get(msal, `accounts[0].idTokenClaims`);
        if (idToken) {
            return {
                firstName: idToken.given_name,
                lastName: idToken.family_name
            }
        }
        return undefined;
    }
}