<template>
  <v-expand-transition>
    <v-bottom-navigation
      v-model="selectedNav"
      class="navigation-bar"
      color="primary"
      @change="navigationChange"
      :height="80"
      v-if="NavigationStore.mobileMapView === false"
    >
      <v-btn v-for="nav in navItems" :value="nav.id" :key="nav.id">
        <span>{{ nav.title }}</span>

        <v-icon>{{ nav.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-expand-transition>
</template>

<script>
import _ from "lodash";

import { NavigationStore } from "../../stores/NavigationStore.js";

const NAV_ITEMS = [
  {
    icon: "mdi-clipboard-text-outline",
    id: "items.surveys",
    title: "Surveys",
  },
  {
    icon: "mdi-plus",
    id: "items.create",
    title: "New Survey",
  },
  {
    icon: "mdi-account-group-outline",
    id: "items.company",
    title: "Company",
  },
  {
    icon: "mdi-cog-outline",
    id: "items.settings",
    title: "Settings",
  },
];

export default {
  name: "NavigationBar",
  components: {},
  computed: {
    navItems: function () {
      return _.filter(NAV_ITEMS, (nav) => NavigationStore.shouldShow(nav.id));
    },
  },
  methods: {
    navigationChange: function (item) {
      NavigationStore.toggleItem(item);
    },
  },
  data() {
    return {
      NavigationStore,
      navClose: false,
      selectedNav: NavigationStore.getSelectedKey(),
    };
  },
};
</script>

<style scoped>
.navigation-bar {
  position: fixed;
  bottom: 0px;
  z-index: 101;
}

.navigation-bar > button {
  height: 56px !important;
  background-color: white !important;
}
</style>
