<template>
  <div>
    <v-data-table
      class="invite-list-table"
      :headers="tableHeaders"
      :items="invites"
      :mobile-breakpoint="0"
      :server-items-length="totalInvites"
      :loading="loading"
      loading-text="Loading invites..."
      @update:options="updateOptions"
    >
      <template #[`item.actions`]="{ item }">
        <v-icon small @click="openDeleteDialog(item)"> mdi-delete </v-icon>
      </template>
      <template #[`footer.prepend`]>
        <v-btn text small @click="addDialog = true" class="invite-add-button">
          <v-icon> mdi-plus </v-icon>Add Invite
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="deleteDialog" max-width="320px">
      <v-card>
        <v-card-title class="text-h5">Delete Invite</v-card-title>
        <v-card-text>
          Warning, this will rescind the invite for:
          <b>{{ selectedDeleteInvite }}</b
          ><br />Do you want to continue?
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="deleteInvite()"> Yes </v-btn>
          <v-btn color="blue darken-1" text @click="deleteDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addDialog" max-width="320px">
      <v-card>
        <v-card-title class="text-h5">Invite a Surveyor</v-card-title>
        <v-card-text>
          <v-text-field
            label="Email"
            v-model="toInvite"
            :rules="[rules.required, rules.email]"
          ></v-text-field>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="addInvite()">
            Invite
          </v-btn>
          <v-btn color="blue darken-1" text @click="clearDialogs()">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import UserService from "@/services/UserService";

const HEADERS = [
  { text: "Email", value: "email" },
  { text: "", value: "actions" },
];

export default {
  name: "InviteList",
  computed: {
    selectedDeleteInvite: function () {
      return _.get(this.toDelete, "email");
    },
  },
  components: {},
  data: function () {
    return {
      addDialog: false,
      deleteDialog: false,
      invites: [],
      loading: false,
      options: {},
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      tableHeaders: HEADERS,
      toDelete: undefined,
      toInvite: undefined,
      totalInvites: 0,
    };
  },
  methods: {
    addInvite: function () {
      const invite = { email: this.toInvite };
      this.clearDialogs();
      UserService.addInvite(invite).then(() => {
        this.fetchResult();
      });
    },
    clearDialogs: function () {
      this.addDialog = false;
      this.deleteDialog = false;
      this.toDelete = undefined;
      this.toInvite = undefined;
    },
    deleteInvite: function () {
      const deleteId = _.get(this.toDelete, "id");
      this.clearDialogs();
      if (deleteId) {
        UserService.deleteInvite(deleteId).then(() => {
          this.fetchResult();
        });
      }
    },
    fetchResult: function () {
      const pageNumber = this.options.page;
      const pageSize = this.options.itemsPerPage;
      const sortField = _.get(this.options, "sortBy[0]", "");
      const sortOrder = _.get(this.options, "sortDesc[0]", false)
        ? "desc"
        : "asc";
      this.loading = true;
      UserService.getInvites(
        pageNumber - 1,
        pageSize,
        "",
        sortField,
        sortOrder
      ).then((response) => {
        this.invites = response.results;
        this.totalInvites = response.totalResults;
        this.loading = false;
      });
    },
    openDeleteDialog: function (item) {
      this.deleteDialog = true;
      this.toDelete = item;
    },
    updateOptions: function (options) {
      this.options = options;
      this.fetchResult();
    },
  },
  mounted() {},
  props: {},
  watch: {},
};
</script>

<style>
.invite-list-table .v-data-footer {
  justify-content: right;
  padding: 10px;
}

.invite-list-table .v-data-footer__select {
  display: none;
}

.invite-add-button {
  margin-right: auto;
}
</style>
