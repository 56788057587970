<template>
  <div>
    <v-progress-linear
      :active="responseStateSaving"
      indeterminate
    ></v-progress-linear>
    <v-expansion-panel-content>
      <v-radio-group v-model="SurveyStore.placeResponse" column>
        <v-radio
          v-for="responseType in OrgInfoStore.responseCodes"
          :label="responseType.response"
          :value="responseType"
          :key="responseType.abbreviation"
        ></v-radio>
      </v-radio-group>
      <div class="place-detail-action-buttons">
        <v-btn
          color="primary"
          :disabled="responseStateUnchanged || responseStateSaving"
          @click="saveResponseState()"
        >
          Save
        </v-btn>
        <v-btn color="grey" @click="resetResponseState()"> Reset </v-btn>
      </div>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import _ from "lodash";

import { OrgInfoStore } from "@/stores/OrgInfoStore";
import { SurveyStore } from "@/stores/SurveyStore.js";

import SurveyService from "@/services/SurveyService";

export default {
  name: "PlaceResponseForm",
  computed: {
    responseStateUnchanged: function () {
      return (
        _.get(SurveyStore.placeResponse, "abbreviation", "") ===
        _.get(
          SurveyStore.survey,
          `places.${SurveyStore.selectedPlaceId}.responseCode.abbreviation`,
          ""
        )
      );
    },
  },
  components: {},
  data: function () {
    return {
      responseStateSaving: false,

      OrgInfoStore,
      SurveyStore,
    };
  },
  methods: {
    getPlaceLabel: function (place) {
      const overrideLabel = _.get(place, "overrides.label");
      if (overrideLabel !== undefined && overrideLabel !== null) {
        return overrideLabel;
      }
      return _.get(place, "mapObject.address.number", "N/A");
    },

    setPlaceResponse: function (placeId) {
      if (placeId !== undefined) {
        SurveyStore.placeResponse = _.get(
          SurveyStore.survey,
          `places.${placeId}.responseCode`
        );
      }
    },
    saveResponseState: function () {
      this.responseStateSaving = true;
      SurveyService.setPlaceResponse(
        SurveyStore.survey.id,
        SurveyStore.selectedPlaceId,
        SurveyStore.placeResponse
      )
        .then((response) => {
          this.responseStateSaving = false;
          if (!response.ok) {
            throw new Error("Did not receive a successful response", {
              cause: response,
            });
          }

          // update the attempts
          response.json().then((json) => {
            _.forEach(json.places, (placeDetail, placeId) => {
              if (_.has(SurveyStore.survey.places, placeId)) {
                _.set(
                  SurveyStore.survey,
                  `places.${placeId}.attempts`,
                  placeDetail.attempts
                );
              }
              if (SurveyStore.selectedPlaceId === placeId) {
                SurveyStore.placeAttempts = placeDetail.attempts;
              }
            });
          });

          SurveyStore.selectedPlace.setResponseCode(SurveyStore.placeResponse);
          // increment the survey revision number to indicate the survey has changed
          SurveyStore.surveyRev = SurveyStore.surveyRev + 1;
        })
        .catch((error) => {
          // TODO show snackbar with error
          this.responseStateSaving = false;
          console.error(error);
        });
    },
    resetResponseState: function () {
      SurveyStore.placeResponse = _.get(
        SurveyStore.survey,
        `places.${SurveyStore.selectedPlaceId}.responseCode`,
        ""
      );
    },
  },
  mounted() {
    this.setPlaceResponse(SurveyStore.selectedPlaceId);
  },
  props: {},
  watch: {
    "SurveyStore.selectedPlaceId"(newId) {
      this.setPlaceResponse(newId);
    },
  },
};
</script>

<style>
.place-detail-action-buttons > button:nth-child(2) {
  float: right;
}

.place-detail-action-buttons.top-padding {
  padding-top: 24px;
}
</style>
