<template>
  <v-card>
    <v-card-text>
      <v-text-field
        solo
        v-model="searchTerm"
        class="surveyor-search-text-field"
        label="Search"
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
      <div v-if="surveyors.length > 0" class="search-results">
        <v-checkbox
          class="search-results-checkbox"
          v-for="surveyor in surveyors"
          v-model="selectedSurveyors"
          :key="surveyor.userId"
          :label="getFullName(surveyor)"
          :value="surveyor.userId"
        ></v-checkbox>
      </div>
      <div v-else class="surveyor-search-no-results">No Results</div>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        @click="addToSurvey()"
        :disabled="selectedSurveyors.length === 0"
      >
        {{ addButtonText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";

import UserService from "@/services/UserService";

export default {
  name: "SurveyorSearchTool",
  components: {},
  computed: {
    addButtonText: function () {
      return this.selectedSurveyors.length === 0
        ? "Add Surveyors"
        : this.selectedSurveyors.length === 1
        ? "Add Surveyor"
        : `Add ${this.selectedSurveyors.length} Surveyors`;
    },
  },
  data: function () {
    return {
      items: [],
      searchTerm: "",
      selectedSurveyors: [],
      surveyors: [],
      surveyorMap: {},
    };
  },
  methods: {
    addToSurvey: function () {
      if (this.addSurveyors) {
        const surveyorsToAdd = {};
        _.forEach(this.selectedSurveyors, (userId) => {
          surveyorsToAdd[userId] = this.surveyorMap[userId];
        });
        this.addSurveyors(surveyorsToAdd);
      }
      this.clear();
    },
    clear: function () {
      this.selectedSurveyors = [];
      this.items = [];
      this.searchTerm = "";
    },
    getFullName: function (surveyor) {
      return `${surveyor.firstName} ${surveyor.lastName}`;
    },
    searchFunc: function (val) {
      if (val !== undefined && val !== null) {
        UserService.getSurveyors(0, 50, val, "", "").then((response) => {
          this.surveyors = response.results;
          _.forEach(this.surveyors, (surveyor) => {
            this.surveyorMap[surveyor.userId] = surveyor;
          });
        });
      }
    },
  },
  mounted() {
    this.searchFunc("");
  },
  props: {
    addSurveyors: Function,
  },
  watch: {
    searchTerm: function (query) {
      this.searchFunc(query);
    },
  },
};
</script>

<style scoped>
.surveyor-search-text-field {
  padding-top: 24px;
}

.surveyor-search-no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 420px;
}

.search-results {
  height: 420px;
  overflow-y: auto;
}

.search-results-checkbox {
  margin-top: 0px;
}
</style>
