<template>
  <div class="survey-list-page">
    <v-window
      v-model="SurveyStore.currentPage"
      class="survey-list-page-window"
      touchless
    >
      <v-window-item value="survey-list">
        <div class="survey-list">
          <PageHeader header="Surveys" icon="mdi-clipboard-text-outline" />
          <PageContent>
            <v-data-table
              class="survey-list-table"
              :headers="tableHeaders"
              :items="SurveyStore.surveyList"
              :mobile-breakpoint="0"
              :search="SurveyStore.surveySearchTerm"
              :server-items-length="SurveyStore.totalSurveys"
              :loading="SurveyStore.isSurveyListLoading"
              loading-text="Loading surveys..."
              @click:row="openSurvey"
              @update:options="updateOptions"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="SurveyStore.surveySearchTerm"
                  label="Search"
                  class="mx-4 survey-list-search"
                  append-icon="mdi-magnify"
                  @input="SurveyStore.fetchSurveys"
                ></v-text-field>
              </template>
              <template #[`item.createTime`]="{ item }">
                <span>{{ new Date(item.createTime).toLocaleString() }}</span>
              </template>
            </v-data-table>
          </PageContent>
        </div>
      </v-window-item>

      <v-window-item eager value="survey-detail">
        <div class="survey-detail">
          <PageHeader
            :header="header"
            icon="mdi-arrow-left"
            :iconClick="SurveyStore.backButton"
          />
          <PageContent>
            <v-progress-linear
              :active="SurveyStore.isSurveyLoading"
              indeterminate
            ></v-progress-linear>
            <SurveyPage :backButtonClick="SurveyStore.backButton" />
          </PageContent>
        </div>
      </v-window-item>

      <v-window-item v-if="LayoutStore.isMobile()" eager value="map-view">
        <div class="survey-detail">
          <PageHeader
            :header="header"
            icon="mdi-arrow-left"
            :iconClick="SurveyStore.backButton"
          />
          <PageContent>
            <MapContainer />
          </PageContent>
        </div>
      </v-window-item>

      <v-window-item eager value="place-detail">
        <PlaceDetailPage />
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import _ from "lodash";

import MapContainer from "@/components/MapContainer.vue";
import PageContent from "@/components/pages/PageContent.vue";
import PageHeader from "@/components/pages/PageHeader.vue";
import PlaceDetailPage from "@/components/pages/place/PlaceDetailPage.vue";
import SurveyPage from "@/components/pages/survey/SurveyPage.vue";

import SurveyService from "@/services/SurveyService";

import { LayoutStore } from "@/stores/LayoutStore.js";
import { NavigationStore } from "@/stores/NavigationStore.js";
import { SurveyStore, SurveyPages } from "@/stores/SurveyStore.js";

export const SurveyListPageLayer = "SurveyListPage";
export const SurveyListPageMarkerLayer = `${SurveyListPageLayer}-Markers`;

const HEADERS = [
  {
    cellClass: "survey-name-column",
    text: "Name",
    align: "start",
    value: "name",
  },
  { text: "% Complete", value: "percent" },
];

export default {
  name: "SurveyListPage",
  components: {
    MapContainer,
    PageContent,
    PageHeader,
    PlaceDetailPage,
    SurveyPage,
  },
  data() {
    return {
      header: "",
      tableHeaders: HEADERS,

      LayoutStore,
      NavigationStore,
      SurveyStore,
    };
  },
  methods: {
    openSurvey: function (survey) {
      this.header = survey.name;
      SurveyStore.isSurveyLoading = true;
      SurveyStore.navigateToPage(SurveyPages.DETAIL);

      // get survey details
      SurveyService.getSurvey(survey.id).then((response) => {
        SurveyStore.survey = response;
        SurveyStore.places = _.get(response, "places");
        SurveyStore.isSurveyLoading = false;
      });
    },
    updateOptions: function (options) {
      SurveyStore.surveyListOptions = options;
      SurveyStore.fetchSurveys();
    },
  },
  mounted() {
    // register callback
    NavigationStore.registerChangeListener("SurveyListPage", () =>
      SurveyStore.clearState()
    );
  },
  props: {},
  watch: {
    "NavigationStore.mobileMapView"(newValue) {
      if (newValue === true) {
        SurveyStore.navigateToPage(SurveyPages.MAP);
      }
    },
  },
};
</script>

<style>
.survey-list-page {
  height: 100%;
  width: 100%;
}

.survey-list-page-window,
.survey-list-page-window > div:first-child {
  height: inherit;
  transition: 0.5s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.survey-list-page tbody tr {
  cursor: pointer;
}

.survey-name-column {
  max-width: 238px;
}

.btn-container {
  display: inline-block;
  position: relative;
  height: 1em;
}

button {
  position: absolute;
}

.survey-list {
  width: 100%;
  height: 100%;
}

.survey-detail {
  position: absolute;
  width: 100%;
  height: 100%;
}

.survey-list-search {
  padding-top: 18px;
}

.survey-list-table .v-data-footer {
  justify-content: right;
  padding: 10px;
}

.survey-list-table .v-data-footer__select {
  display: none;
}
</style>
