<template>
  <div>
    <v-data-table
      class="survey-list-table"
      :headers="tableHeaders"
      :items="surveyors"
      :mobile-breakpoint="0"
      :search="search"
      :server-items-length="totalSurveyors"
      :loading="loading"
      loading-text="Loading surveyors..."
      @update:options="updateOptions"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4 survey-list-search"
          append-icon="mdi-magnify"
          @input="fetchResult"
        ></v-text-field>
      </template>
      <template #[`item.firstName`]="{ item }">
        <span>{{ `${item.firstName} ${item.lastName}` }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from "lodash";

import UserService from "@/services/UserService";

const HEADERS = [
  { text: "Name", value: "firstName" },
  { text: "Email", value: "email" },
];

export default {
  name: "SurveyorsList",
  computed: {},
  components: {},
  data: function () {
    return {
      loading: false,
      options: {},
      search: "",
      surveyors: [],
      tableHeaders: HEADERS,
      totalSurveyors: 0,
    };
  },
  methods: {
    fetchResult: function () {
      const pageNumber = this.options.page;
      const pageSize = this.options.itemsPerPage;
      const sortField = _.get(this.options, "sortBy[0]", "");
      this.loading = true;
      UserService.getSurveyors(
        pageNumber - 1,
        pageSize,
        this.search,
        sortField,
        "asc"
      ).then((response) => {
        this.surveyors = response.results;
        this.totalSurveyors = response.totalResults;
        this.loading = false;
      });
    },
    updateOptions: function (options) {
      this.options = options;
      this.fetchResult();
    },
  },
  mounted() {},
  props: {},
  watch: {},
};
</script>

<style>
</style>
