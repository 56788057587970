<template>
  <div class="settings-page">
    <PageHeader header="Profile" icon="mdi-account" />
    <PageContent>
      <div class="settings-detail">
        <v-expansion-panels v-model="selectedPanel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="settings-detail-expansion-header"
              ><v-icon left> mdi-account-details </v-icon>
              <h3>User Info</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <div>
                <span :title="UserInfoStore.name"
                  ><b>Name:</b> {{ UserInfoStore.name }}</span
                >
              </div>
              <div>
                <span :title="UserInfoStore.email"
                  ><b>Email:</b> {{ UserInfoStore.email }}</span
                >
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="settings-detail-expansion-header"
              ><v-icon left> mdi-office-building-outline </v-icon>
              <h3>Company Info</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <div>
                <span><b>Name:</b> {{ OrgInfoStore.name }}</span>
              </div>
              <div>
                <span><b>ID:</b> {{ OrgInfoStore.id }}</span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- TODO implement this -->
          <!-- <v-expansion-panel>
            <v-expansion-panel-header class="settings-detail-expansion-header"
              ><v-icon left> mdi-map-marker-star-outline </v-icon>
              <h3>Map Home Position</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content> TODO </v-expansion-panel-content>
          </v-expansion-panel> -->
        </v-expansion-panels>
        <v-btn text x-large @click="signout" class="sign-out-button">
          <v-icon> mdi-logout-variant </v-icon>Sign Out
        </v-btn>
      </div>
    </PageContent>
  </div>
</template>

<script>
import PageContent from "@/components/pages/PageContent.vue";
import PageHeader from "@/components/pages/PageHeader.vue";

import { OrgInfoStore } from "@/stores/OrgInfoStore.js";
import { UserInfoStore } from "@/stores/UserInfoStore.js";

import AuthUtil from "@/util/AuthUtil";

export default {
  name: "SettingsPage",
  components: {
    PageContent,
    PageHeader,
  },
  data() {
    return {
      selectedPanel: [0, 1],
      OrgInfoStore,
      UserInfoStore,
    };
  },
  methods: {
    signout: function () {
      AuthUtil.logout();
    },
  },
  mounted() {},
  props: {},
};
</script>

<style>
.settings-page {
  height: 100%;
}

.settings-page .v-expansion-panel {
  border-radius: 0px !important;
}

.settings-page .v-expansion-panel::before {
  box-shadow: 0px 1px 0px 0px rgb(0 0 0 / 14%),
    0px -1px 0px 0px rgb(0 0 0 / 14%);
}

.settings-detail-expansion-header > i {
  justify-content: left !important;
  flex: none !important;
}

.settings-detail-expansion-header > h3 {
  text-transform: capitalize;
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
  transition: none;
}

.settings-detail-content.no-padding > div:first-child {
  padding: 0px;
}

.dialog-subheader {
  padding: 0 24px 12px !important;
}

.sign-out-button {
  text-transform: capitalize;
  font-size: 14px !important;
  width: 100%;
  text-align: left;
  justify-content: left;
  margin: 16px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0px;
}

.sign-out-button > span > i {
  padding-right: 8px;
}
</style>
